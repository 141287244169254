import "./HandleConfirmationPopup.scss";
import { useEffect, useState } from "react";
import ConfirmationPopup from "../../hoc/ConfirmationPopup/ConfirmationPopup";
import { toast } from "react-toastify";
import { set } from "date-fns";
import Spinner from "../../components/UI/Spinner/Spinner";

interface IHandleConfirmationPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  questionText: string;
  confirmText: string;
  denyText?: string;
  loading?: boolean;
}

function HandleConfirmationPopup(props: IHandleConfirmationPopupProps) {
  const handleConfirm = () => {
    props.onConfirm();
  };
  const handleDeny = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.loading === false && props.loading !== undefined) {
      props.onClose();
    }
  }, [props.loading]);
  
  return (
    <ConfirmationPopup
      showPopup={props.showPopup}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      loading={props.loading}
    >
      <div className="general-popup-wrapper">
        <div className="general-popup-form">
          {props.loading !== undefined && props.loading === true ? (
            <>
              <Spinner />
              <h2 className="text-l">Vänligen lämna inte detta fönster</h2>
            </>
          ) : (
            <>
              <div className="general-popup-form__header">
                <h2 className="text-l">{props.questionText}</h2>
              </div>
              <button className="red-button" onClick={handleConfirm}>
                {props.confirmText}
              </button>
            </>
          )}
        </div>
      </div>
    </ConfirmationPopup>
  );
}

export default HandleConfirmationPopup;
