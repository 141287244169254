import { ICreateUserSpec } from "../../types/api";

export const createUserQuery = `mutation createUser($input: UserInput!) {
          createUser(input: $input) {
            ...on User {
              member_id
            }
            ...on Error {
              message
            }
          }
        }
    `;
export const createUserQueryFn = (input: ICreateUserSpec, set_id: boolean) => {
  const query = `createUser(input: $input, set_id: $set_id) {
            ...on User {
              member_id
            }
            ...on Error {
              message
            }
          }
    `;
  const variables = { input: input, set_id: set_id };
  const inputs = `$input: UserInput!, $set_id: Boolean!`;
  return {
    inputs,
    query,
    variables,
  };
};
export const updateUserQuery = `
       mutation updateUser($member_id: String!, $input_data: UpdateUserInput!, $old_email: String) {
          updateUser(member_id: $member_id, input_data: $input_data, old_email: $old_email) {
            ...on User {
              member_id
            }
            ...on Error {
              message
            }
          }
        }`;

export const listUsersQuery = `
    query MyQuery {
      listUsers {
         ... on User {
          email
          first_name
          last_name
          member_type
          member_id
          phone_number
          noshow_count
          frozen
        }
        ... on Error {
          code
          message
        }
      }
    }
  `;

export const deleteUserQuery = `
     mutation deleteUser($member_id: String!) {
          deleteUser(member_id: $member_id) {
            ... on User {
              member_id
            }
            ... on Error {
              message
              code
            }
          }
        }
    `;

export const getUserByIDQuery = `
    query MyQuery($member_id: String!) {
      getUserByID(member_id: $member_id) {
        ... on User {
          account_type
          email_cc
          phone_number
          home_phone
          work_phone
          adress
          city
          zip_code
          country
          company
          title
          ranking
          hidden_info
          gender
          comment
          activated_at
          created_at
          updated_at
          rehab_access
          gym_access
          email
          last_name
          member_type
          member_id
          birth_data
          first_name
          noshow_count
          frozen
        }
        ... on Error {
          message
          code
        }
      }
    }`;

export const getUserByMemberIdQueryFn = (member_id: string) => {
  const query = `
    getUserByID(member_id: $member_id) {
        ... on User {
          account_type
          email_cc
          phone_number
          home_phone
          work_phone
          adress
          city
          zip_code
          country
          company
          title
          ranking
          massemail
          hidden_info
          gender
          gym_access
          comment
          rehab_access
          activated_at
          created_at
          updated_at
          email
          last_name
          member_type
          member_id
          birth_data
          first_name
          noshow_count
          frozen
        }
        ... on Error {
          message
          code
        }
      }
    `;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    variables,
    inputs,
  };
};

export const listUsersQueryFn = (
  requestor: "admin" | "user",
  limit: number,
  start_key: string,
  member_type?: string
) => {
  let query = "";
  if (requestor === "admin") {
    query = `
    listUsers(limit: $limit, start_key: $start_key, member_type: $member_type) {

         ... on displayUsers {
          users {
            email
            first_name
            last_name
            member_type
            member_id
            phone_number
            noshow_count
            frozen
          } 
          next_start_key
        }
        ... on Error {
          code
          message
        }
      }
  `;
  } else {
    query = `
    listUsers(limit: $limit, start_key: $start_key, member_type: $member_type) {
         ... on displayUsers {
          users {
            email
            first_name
            last_name
            member_type
            member_id
            phone_number
            noshow_count
          } 
          next_start_key
        }
        ... on Error {
          code
          message
        }
      }
  `;
  }
  const inputs = `$limit: Int!, $start_key: String, $member_type: String`;
  const variables = {
    limit: limit,
    start_key: start_key,
    member_type: member_type,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const serachUsersQueryFn = (
  search: string,
  requestor: "admin" | "user",
  searchable: boolean
) => {
  let admin = false;
  let query = "";
  if (requestor === "admin") {
    admin = true;
    query = `
    searchUser(search: $search, admin: $admin) {
         ...on User {
          member_type
          email
          first_name
          last_name
          member_id
          phone_number
          noshow_count
          frozen
         }
          ... on Error {
            code
            message
          }
      }
  `;
  } else if (requestor === "user" && searchable) {
    admin = true;
    query = `
    searchUser(search: $search, admin: $admin) {
         ...on User {
          member_type
          first_name
          last_name
          member_id
          phone_number
         }
          ... on Error {
            code
            message
          }
      }
  `;
  } else {
    query = `
    searchUser(search: $search, admin: $admin) {
         ...on User {
          member_type
          first_name
          last_name
          member_id
          phone_number
         }
          ... on Error {
            code
            message
          }
      }
  `;
  }

  const inputs = `$search: String!, $admin: Boolean`;
  const variables = { search: search, admin: admin };

  return {
    query,
    inputs,
    variables,
  };
};

export const exportUserDataQueryFn = (member_type: string) => {
  const query = `
    exportUserData(member_type: $member_type) {
            ... on DataExport {
              __typename
              url
            }
            ... on Error {
              code
              message
            }
          }
    `;
  const inputs = `$member_type: String`;
  const variables = { member_type: member_type };
  return {
    query,
    inputs,
    variables,
  };
};

export const freezeMembershipQueryFn = (
  member_id: string,
  start_date: string,
  end_date: string,
  member_type: string
) => {
  const startDateString = start_date.slice(0, 10);
  const endDateString = end_date.slice(0, 10);
  const query = `
    freezeMembership(member_id: $member_id, start_date: $start_date, end_date: $end_date, member_type: $member_type) {
            ... on User {
              member_id
              first_name
              last_name
            }
            ... on Error {
              code
              message
            }
          }
    `;
  const inputs = `$member_id: String!, $start_date: String!, $end_date: String!, $member_type: String!`;
  const variables = {
    member_id: member_id,
    start_date: startDateString,
    end_date: endDateString,
    member_type: member_type,
  };
  return {
    query,
    inputs,
    variables,
  };
};
