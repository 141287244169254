interface IAdminStatisticProps {}

function AdminStatistic(props: IAdminStatisticProps) {
  return (
    <div className="admin-handle-statistic-layout">
      <h1 className="text-l primary-text">Statistik</h1>
      <div className="handle-statistic-actions-wrapper"></div>
    </div>
  );
}
export default AdminStatistic;
