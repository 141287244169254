import "./MoveBookingPopup.scss";
import MidPopup from "../../../hoc/MidPopup/MidPopup";
import { ICourt, ISummaryItem } from "../../../types/api";
import { useEffect, useState } from "react";
import { moveBooking } from "../../../api/bookings/bookings";
import { toast } from "react-toastify";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";
interface IMoveBookingPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  bookedSlot?: ISummaryItem;
  slotForMovement?: ISummaryItem;
  newCourt?: ICourt;
  courts: ICourt[];
  filterDate: Date;
}

function MoveBookingPopup(props: IMoveBookingPopupProps) {
  const {
    onClose,
    onConfirm,
    showPopup,
    bookedSlot,
    slotForMovement,
    newCourt,
    courts,
    filterDate,
  } = props;
  const [isMoving, setIsMoving] = useState(false);
  const handleClose = () => {
    onClose();
  };

  const handleMoveBooking = async () => {
    if (
      slotForMovement === undefined ||
      bookedSlot === undefined ||
      newCourt === undefined
    ) {
      return;
    }
    setIsMoving(true);
    const isMovingToast = toast.loading("Flyttar bokning...");
    const oldBooking = bookedSlot.booking[0];
    let newBooking = JSON.parse(JSON.stringify(bookedSlot.booking[0]));

    console.log(oldBooking.datetime_start);
    if (newBooking) {
      newBooking.datetime_start =
        newBooking.datetime_start.split("T")[0] +
        "T" +
        slotForMovement.start_time;
      newBooking.court_id = newCourt.court_id;
    }
    try {
      const response = await moveBooking(
        newBooking,
        oldBooking.datetime_start,
        oldBooking.court_id
      );
      if (response && !("message" in response)) {
        setIsMoving(false);
        toast.dismiss(isMovingToast);
        toast.success("Bokningen har flyttats");
        onConfirm();
        onClose();
      } else {
        toast.dismiss(isMovingToast);
        setIsMoving(false);
      }
    } catch (error: any) {
      toast.dismiss(isMovingToast);
      setIsMoving(false);
      toast.error(error);
    }
  };
  if (
    !props.bookedSlot ||
    !props.slotForMovement ||
    bookedSlot === undefined ||
    slotForMovement === undefined
  ) {
    return null;
  }
  const bookedCourt = courts.find(
    (court) => court.court_id === bookedSlot.booking[0].court_id
  );
  const booking = bookedSlot.booking[0];
  const { dateAndMonth } = formatDateToSwedish(filterDate);
  return (
    <MidPopup
      showPopup={showPopup}
      onClose={handleClose}
      onConfirm={onConfirm}
      leftTopElement={<span className="text-m">Flytta bokning</span>}
      isLoading={isMoving}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-top">
            <button
              className="text-m"
              onClick={() => handleMoveBooking()}
              disabled={isMoving}
            >
              Flytta
            </button>
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Bokning: </span>
                <div className="text-m primary-text text-svg-wrapper">
                  {capitalizeWords(booking.member_name)} - {dateAndMonth}
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Medspelare: </span>
                <div className="text-m primary-text text-svg-wrapper">
                  {booking.co_players.length}
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Gäster: </span>
                <div className="text-m primary-text text-svg-wrapper">
                  {booking.guests.length}
                </div>
              </div>
              {booking.coach_id.length > 0 && (
                <div className="general-popup-flex-between">
                  <span className="text-m primary-text">Tränare: </span>
                  <div className="text-m primary-text text-svg-wrapper">
                    {booking.coach_id[0]}
                  </div>
                </div>
              )}
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Från: </span>
                <div className="text-m primary-text text-svg-wrapper">
                  Kl. {bookedSlot.start_time.slice(0, 5)} - Bana{" "}
                  {bookedCourt?.name}
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text ">Till:</span>
                <div className="text-m primary-text text-svg-wrapper">
                  Kl. {slotForMovement.start_time.slice(0, 5)} - Bana{" "}
                  {newCourt?.name}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </MidPopup>
  );
}

export default MoveBookingPopup;
