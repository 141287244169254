import { useEffect, useState, useCallback, useRef } from "react";
import "./AdminHandleUsers.scss";
import { IGuest, IPermission, IUser } from "../../types/api";
import { exportUserData, searchUsers } from "../../api/users/users";
import CreateUserPopup from "../../containers/Users/CreateUserPopup/CreateUserPopup";
import EditUserPopup from "../../containers/Users/EditUserPopup/EditUserPopup";
import { capFirstAndRemoveDash, capitalizeWords } from "../../shared/utility";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import { toast } from "react-toastify";
import { debounce } from "../../shared/utility";
import { exportGuestData, searchGuests } from "../../api/guests/guests";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../shared/enums";
import EditGuestPopup from "../../containers/Guests/EditGuestPopup/EditGuestPopup";
import HandleConfirmationPopup from "../../containers/HandleConfirmation/HandleConfirmationPopup";
import FreezeUserPopup from "../../containers/Users/FreezeUserPopup.scss/FreezeUserPopup";
import ChangePasswordForUserPopup from "../../containers/Admin/ChangePasswordForUserPopup/ChangePasswordForUserPopup";
import frozen from "../../assets/images/frozen.svg";
import { searchQueueUsers } from "../../api/queueUser/queueUsers";

interface IAdminHandleUsersProps {}

function AdminHandleUsers(props: IAdminHandleUsersProps) {
  const navigate = useNavigate();
  const [users, setUsers] = useState<IUser[]>([]);
  const [queueUsers, setQueueUsers] = useState<IUser[]>([]);
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [currentSelectedPermission, setCurrentSelectedPermission] =
    useState<IPermission>();
  const [searchString, setSearchString] = useState<string>("");
  const [guestSearchString, setGuestSearchString] = useState<string>("");
  const [queueUsersSearchString, setQueueUsersSearchString] =
    useState<string>("");
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([]);
  const [searchedGuests, setSearchedGuests] = useState<IGuest[]>([]);
  const [searchedQueueUsers, setSearchedQueueUsers] = useState<IUser[]>([]);
  const [currentEditUserId, setCurrentEditUserId] = useState<string>("");
  const [currentEditGuestId, setCurrentEditGuestId] = useState<string>("");
  const [showEditUserPopup, setShowEditUserPopup] = useState<boolean>(false);
  const [showEditGuestPopup, setShowEditGuestPopup] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [showCreateUserPopup, setShowCreateUserPopup] =
    useState<boolean>(false);

  const [showFreezeUserPopup, setShowFreezeUserPopup] =
    useState<boolean>(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] =
    useState<boolean>(false);
  const [showLoadingExportPopup, setShowLoadingExportPopup] =
    useState<boolean>(false);

  const [currentMemberType, setCurrentMemberType] = useState<
    "members" | "guests" | "queueUsers"
  >("members");

  const [loading, setLoading] = useState<boolean>(false);

  const exportData = async (
    type: "guests" | "members" | "queueUsers",
    member_type?: string
  ) => {
    setLoadingExport(true);
    const permissionString = member_type ? member_type : "";
    try {
      let response = null;
      if (type === "members") {
        response = await exportUserData(permissionString);
      }
      if (type === "queueUsers") {
        response = await exportUserData("kö");
      }
      if (type === "guests") {
        response = await exportGuestData();
      }
      if (response && !("message" in response)) {
        setLoadingExport(false);
        const a = document.createElement("a");
        a.href = response.url;
        a.download = "";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      setLoadingExport(false);
    } catch (error: any) {
      toast.error(error);
      setLoadingExport(false);
    }
  };

  const fetchChosenItems = async ({
    fetchUsers = false,
    fetchPermissions = false,
    fetchGuests = false,
    fetchQueueUsers = false,
    limit = 30,
    start_key = "none",
  }) => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        fetchUsers: fetchUsers,
        fetchPermissions: fetchPermissions,
        fetchGuests: fetchGuests,
        fetchQueueUsers: fetchQueueUsers,
        permission_id: currentSelectedPermission?.member_type,
        limit: limit,
        start_key: start_key,
      });
      if (fetchUsers && response.users && !("message" in response.users)) {
        setUsers(response.users.users);
      }
      if (
        fetchQueueUsers &&
        response.queueUsers &&
        !("message" in response.queueUsers)
      ) {
        console.log(response);
        setQueueUsers(response.queueUsers.users);
      }
      if (
        fetchPermissions &&
        response.permissions &&
        !("message" in response.permissions)
      ) {
        setPermissions(response.permissions);
      }
      if (fetchGuests && response.guests && !("message" in response.guests)) {
        setGuests(response.guests.guests);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchUsers: true,
      fetchPermissions: true,
      fetchGuests: true,
      fetchQueueUsers: true,
    });
  }, []);

  // useEffect(() => {
  //   fetchChosenItems({
  //     fetchUsers: true,
  //   });
  // }, [currentSelectedPermission]);

  const lastWarningTimeRef = useRef<number>(0);

  const showWarning = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeRef.current >= 5000) {
      lastWarningTimeRef.current = now;
      toast.warning("Inga användare hittades");
    }
  }, []);

  const lastWarningTimeGuestRef = useRef<number>(0);

  const showWarningGuest = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeGuestRef.current >= 5000) {
      lastWarningTimeGuestRef.current = now;
      toast.warning("Inga gäster hittades");
    }
  }, []);

  const lastWarningTimeQueueUsersRef = useRef<number>(0);

  const showWarningQueueUsers = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeQueueUsersRef.current >= 5000) {
      lastWarningTimeQueueUsersRef.current = now;
      toast.warning("Inga kö-medlemmar hittades");
    }
  }, []);

  const fetchSearchUsers = async (search: string) => {
    try {
      const response = await searchUsers(search, "admin", true);
      if (response.length === 0) {
        showWarning();
      }
      setSearchedUsers(response);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const fetchSearchGuests = async (search: string) => {
    try {
      const response = await searchGuests(search);
      setSearchedGuests(response);
      if (response.length === 0) {
        showWarningGuest();
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const fetchSearchQueueUsers = async (search: string) => {
    try {
      const response = await searchQueueUsers(search);
      setSearchedQueueUsers(response);
      if (response.length === 0) {
        showWarningQueueUsers();
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleSearchUsers = () => {
    fetchSearchUsers(searchString);
  };
  const handleSearchGuests = () => {
    fetchSearchGuests(guestSearchString);
  };
  const handleSearchQueueUsers = () => {
    fetchSearchQueueUsers(queueUsersSearchString);
  };

  useEffect(() => {
    if (searchString.length > 0) {
      handleSearchUsers();
    } else {
      setSearchedUsers([]);
    }
  }, [searchString]);

  useEffect(() => {
    if (guestSearchString.length > 0) {
      handleSearchGuests();
    } else {
      setSearchedGuests([]);
    }
  }, [guestSearchString]);

  useEffect(() => {
    if (queueUsersSearchString.length > 0) {
      handleSearchQueueUsers();
    } else {
      setSearchedQueueUsers([]);
    }
  }, [queueUsersSearchString]);

  const handleToggleMemberType = (
    type: "members" | "guests" | "queueUsers"
  ) => {
    setSearchString("");
    setGuestSearchString("");
    setQueueUsersSearchString("");
    setCurrentMemberType(type);
  };

  const openEditUser = (userId: string) => {
    setCurrentEditUserId(userId);
    setShowEditUserPopup(true);
  };

  const openEditGuest = (guestId: string) => {
    setCurrentEditGuestId(guestId);
    setShowEditGuestPopup(true);
  };

  const openCreateUser = () => {
    setShowCreateUserPopup(true);
  };

  const linkToUser = (userId: string) => {
    navigate(`${ROUTE.Users}/${userId}`);
  };
  const linkToGuest = (guestId: string) => {
    // navigate(`${ROUTE.Guests}/${guestId}`);
  };

  const usersToDisplay = searchString.length > 0 ? searchedUsers : users;
  const guestsToDisplay =
    guestSearchString.length > 0 ? searchedGuests : guests;

  const queueUsersToDisplay =
    queueUsersSearchString.length > 0 ? searchedQueueUsers : queueUsers;

  return (
    <div className="admin-handle-users-layout">
      <h1 className="text-l primary-text">Medlemmar</h1>
      <div className="handle-users-actions-wrapper">
        <div className="users-search-wrapper">
          <button
            className={`toggle-button text-m ${
              currentMemberType === "members" ? "active" : ""
            }`}
            onClick={() => handleToggleMemberType("members")}
          >
            Medlemmar
          </button>
          <button
            className={`toggle-button text-m ${
              currentMemberType === "guests" ? "active" : ""
            }`}
            onClick={() => handleToggleMemberType("guests")}
          >
            Gäster
          </button>
          <button
            className={`toggle-button text-m ${
              currentMemberType === "queueUsers" ? "active" : ""
            }`}
            onClick={() => handleToggleMemberType("queueUsers")}
          >
            Kö-medlemar
          </button>
          {currentMemberType === "members" && (
            <>
              <div className="search-input-wrapper">
                <input
                  className="users-search-input text-m"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Sök "
                ></input>
                <i className="fa-regular fa-magnifying-glass search-icon"></i>
              </div>
              <select
                className="permissions-select text-m admin-actions-setting"
                value={currentSelectedPermission?.permission_id || ""}
                onChange={(e) => {
                  const selectedPermission = permissions.find(
                    (permission) => permission.permission_id === e.target.value
                  );
                  setCurrentSelectedPermission(selectedPermission);
                }}
              >
                <option value="">Välj medlemstyp att exportera</option>
                {permissions.map((permission, index) => {
                  const name = capitalizeWords(permission.member_type);
                  if (permission.member_type === "Kö") return null;
                  return (
                    <option key={index} value={permission.permission_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </>
          )}
          {currentMemberType === "guests" && (
            <div className="search-input-wrapper">
              <input
                className="users-search-input text-m"
                value={guestSearchString}
                onChange={(e) => setGuestSearchString(e.target.value)}
                placeholder="Sök"
              ></input>
              <i className="fa-regular fa-magnifying-glass search-icon"></i>
            </div>
          )}
          {currentMemberType === "queueUsers" && (
            <div className="search-input-wrapper">
              <input
                className="users-search-input text-m"
                value={queueUsersSearchString}
                onChange={(e) => setQueueUsersSearchString(e.target.value)}
                placeholder="Sök"
              ></input>
              <i className="fa-regular fa-magnifying-glass search-icon"></i>
            </div>
          )}
        </div>
        <div className="users-add-wrapper">
          {currentMemberType === "members" ? (
            <button
              className="users-add-button text-m"
              onClick={() =>
                exportData(
                  currentMemberType,
                  currentSelectedPermission?.member_type
                )
              }
            >
              Exportera medlemmar
              {currentSelectedPermission?.member_type &&
                ": " + currentSelectedPermission?.member_type}
            </button>
          ) : currentMemberType === "queueUsers" ? (
            <button
              className="users-add-button text-m"
              onClick={() => exportData(currentMemberType)}
            >
              Exportera kö-medlemmar
            </button>
          ) : (
            <button
              className="users-add-button text-m"
              onClick={() => exportData(currentMemberType)}
            >
              Exportera gäster
            </button>
          )}
          <button
            className="users-add-button text-m"
            onClick={() => setShowFreezeUserPopup(true)}
          >
            Frys användare
          </button>
          <button
            className="users-add-button text-m"
            onClick={() => setShowChangePasswordPopup(true)}
          >
            Byt lösenord för användare
          </button>
          <button
            className="users-add-button text-m"
            onClick={() => openCreateUser()}
          >
            Lägg till medlem
          </button>
        </div>
      </div>
      <div className="users-table-overflow-wrapper">
        <table className="users-table">
          <thead>
            <tr>
              <th className="text-m secondary-text">Medlemsnummer</th>

              <th className="text-m secondary-text">Förnamn</th>
              <th className="text-m secondary-text">Efternamn</th>
              {currentMemberType === "members" && (
                <th className="text-m secondary-text">No shows</th>
              )}
              {currentMemberType === "guests" && (
                <th className="text-m secondary-text">Antal spel</th>
              )}
              <th className="text-m secondary-text">Medlemskap</th>
              <th className="text-m secondary-text">E-postadress</th>
              <th className="text-m secondary-text">Telefon</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              usersToDisplay.map((user, index) => {
                const firstName = capitalizeWords(user.first_name);
                const memberType = capFirstAndRemoveDash(user.member_type);
                const lastName = capitalizeWords(user.last_name);
                if (currentMemberType !== "members") return null;

                return (
                  <tr key={index}>
                    <td
                      className="text-m primary-text"
                      onClick={() => linkToUser(user.member_id)}
                    >
                      {user.member_id}
                      {user.frozen && (
                        <div className="frozen-user-icon">
                          <img src={frozen} alt="frozen symbol" />
                        </div>
                      )}
                    </td>

                    <td
                      className="text-m primary-text"
                      onClick={() => linkToUser(user.member_id)}
                    >
                      {firstName}
                    </td>
                    <td
                      className="text-m primary-text"
                      onClick={() => linkToUser(user.member_id)}
                    >
                      {lastName}
                    </td>
                    <td
                      className="text-m primary-text"
                      onClick={() => linkToUser(user.member_id)}
                    >
                      {user.noshow_count}
                    </td>
                    <td
                      className="text-m primary-text"
                      onClick={() => linkToUser(user.member_id)}
                    >
                      {memberType}
                    </td>
                    <td
                      className="text-m primary-text"
                      onClick={() => linkToUser(user.member_id)}
                    >
                      {user.email}
                    </td>
                    <td className="text-m primary-text">
                      {user.phone_number}
                      <div
                        className="edit-component"
                        onClick={() => openEditUser(user.member_id)}
                      >
                        <div className="edit-dot"></div>
                        <div className="edit-dot"></div>
                        <div className="edit-dot"></div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {queueUsersToDisplay.map((user, index) => {
              const firstName = capitalizeWords(user.first_name);
              const memberType = capFirstAndRemoveDash(user.member_type);
              const lastName = capitalizeWords(user.last_name);
              if (currentMemberType !== "queueUsers") return null;

              return (
                <tr key={index}>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToUser(user.member_id)}
                  >
                    {user.member_id}
                    {user.frozen && (
                      <div className="frozen-user-icon">
                        <img src={frozen} alt="frozen symbol" />
                      </div>
                    )}
                  </td>

                  <td
                    className="text-m primary-text"
                    onClick={() => linkToUser(user.member_id)}
                  >
                    {firstName}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToUser(user.member_id)}
                  >
                    {lastName}
                  </td>

                  <td
                    className="text-m primary-text"
                    onClick={() => linkToUser(user.member_id)}
                  >
                    {memberType}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToUser(user.member_id)}
                  >
                    {user.email}
                  </td>
                  <td className="text-m primary-text">
                    {user.phone_number}
                    <div
                      className="edit-component"
                      onClick={() => openEditUser(user.member_id)}
                    >
                      <div className="edit-dot"></div>
                      <div className="edit-dot"></div>
                      <div className="edit-dot"></div>
                    </div>
                  </td>
                </tr>
              );
            })}
            {guestsToDisplay.map((guest, index) => {
              const firstName = capitalizeWords(guest.first_name);
              const lastName = capitalizeWords(guest.last_name);
              if (currentMemberType !== "guests") return null;
              if (index > 30) return null;
              const guestTimesPlayed = guest.times_played
                ? guest.times_played
                : 0;
              const guestTimesPlayedAllowed =
                guest.family_member !== null ? 18 : 10;

              return (
                <tr key={index}>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToGuest(guest.member_id)}
                  >
                    {guest.member_id}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToGuest(guest.member_id)}
                  >
                    {firstName}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToGuest(guest.member_id)}
                  >
                    {lastName}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToGuest(guest.member_id)}
                  >
                    {guestTimesPlayed} / {guestTimesPlayedAllowed}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => linkToGuest(guest.member_id)}
                  >
                    Gäst
                  </td>

                  <td
                    className="text-m primary-text"
                    onClick={() => linkToGuest(guest.member_id)}
                  >
                    {guest.email}
                  </td>
                  <td className="text-m primary-text">
                    {guest.phone_number}
                    <div
                      className="edit-component"
                      onClick={() => openEditGuest(guest.member_id)}
                    >
                      <div className="edit-dot"></div>
                      <div className="edit-dot"></div>
                      <div className="edit-dot"></div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CreateUserPopup
        showPopup={showCreateUserPopup}
        onClose={() => setShowCreateUserPopup(false)}
        onConfirm={() => {
          fetchChosenItems({ fetchUsers: true });
        }}
        permissions={permissions}
      />
      <EditUserPopup
        showPopup={showEditUserPopup}
        onClose={() => {
          setShowEditUserPopup(false);
          setCurrentEditUserId("");
        }}
        onConfirm={() => {
          {
            setCurrentEditUserId("");
            setSearchString("");
            fetchChosenItems({ fetchUsers: true });
          }
        }}
        userId={currentEditUserId}
        permissions={permissions}
      />
      <HandleConfirmationPopup
        showPopup={loadingExport}
        onClose={() => {}}
        onConfirm={() => {}}
        questionText="Laddar"
        confirmText="Stäng"
        loading={loadingExport}
      />
      <EditGuestPopup
        showPopup={showEditGuestPopup}
        onClose={() => setShowEditGuestPopup(false)}
        onConfirm={() => {
          setCurrentEditUserId("");
          setGuestSearchString("");
          fetchChosenItems({ fetchGuests: true });
        }}
        guestId={currentEditGuestId}
      />
      <FreezeUserPopup
        showPopup={showFreezeUserPopup}
        onClose={() => setShowFreezeUserPopup(false)}
        onConfirm={() => {
          setCurrentEditUserId("");
          setGuestSearchString("");
          fetchChosenItems({ fetchGuests: true });
        }}
      />

      <ChangePasswordForUserPopup
        showPopup={showChangePasswordPopup}
        onClose={() => setShowChangePasswordPopup(false)}
        onConfirm={() => {}}
      />
    </div>
  );
}

export default AdminHandleUsers;
