import "./CreateBookingPopup.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  ICancelBookingInputType,
  ICoach,
  IConfirmBookingSpec,
  ICourt,
  ICourtType,
  IIndividualWithStatus,
  IInitiateBookingResponse,
  IPermission,
  IPrices,
  ISummaryItem,
  IUser,
} from "../../../types/api";
import { Id, toast } from "react-toastify";
import { CognitoUser } from "../../../types/cognito";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";
import { confirmBooking, expireBooking } from "../../../api/bookings/bookings";
import time from "../../../assets/images/time.svg";
import greenCheck from "../../../assets/images/green-check.svg";
import useBeforeUnload from "../../../shared/hooks/useBeforeUnload";
import singleCross from "../../../assets/images/single-corss.svg";
import { StatusEnum } from "../../../shared/enums";
import { searchUsers } from "../../../api/users/users";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { getSlotByMember } from "../../../api/summaries/summaries";
interface ICreateBookingPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  court: ICourt;
  filterDate: Date;
  courtType: string;
  user: CognitoUser | null;
  timeAndCourtString: string;
  bookingToConfirm?: IInitiateBookingResponse;
  coachesList: string[] | [];
  chosenCoach?: ICoach;
  latestCoPlayers: IUser[] | [];
  coaches: ICoach[];
  adminChosenUser?: IUser;
  price?: string;
  permissions: IPermission[];
  prices: IPrices[];
}
interface IAdditiveOption {
  value: "COACH" | "CO-PLAYER";
}
function CreateBookingPopup(props: ICreateBookingPopupProps) {
  const [userString, setUserString] = useState<string>("");
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([]);
  const [isJunior, setIsJunior] = useState<boolean>(false);
  useEffect(() => {
    if (props.adminChosenUser) {
      setUserString(
        `${capitalizeWords(props.adminChosenUser.first_name)} ${capitalizeWords(
          props.adminChosenUser.last_name
        )}`
      );
    } else {
      setUserString(props.user?.attributes?.name || "");
    }

    if (props.chosenCoach) {
      if (
        props.coachesList.some((coach) => coach === props.chosenCoach?.coach_id)
      ) {
        setChosenCoach(props.chosenCoach.coach_id);
      }
    } else {
      setChosenCoach("");
    }

    const memberType =
      props.user?.signInUserSession.idToken.payload["cognito:groups"];

    if (memberType && memberType[0] === "user") {
      const userPermission = props.permissions.find(
        (permission) =>
          permission.permission_id === props.user?.attributes["custom:role"]
      );
      if (
        userPermission &&
        (userPermission.member_type === "Junior" ||
          userPermission.member_type === "Junior B")
      ) {
        setIsJunior(true);
      }
    }
  }, [props.showPopup]);

  useEffect(() => {
    if (props.showPopup) {
      if (props.bookingToConfirm) {
        const bookingData = {
          booking_id: props.bookingToConfirm.booking_id || "",
          datetime_start: props.bookingToConfirm.datetime_start,
          court_id: props.bookingToConfirm.court_id,
          slot_index: props.bookingToConfirm.slot_index,
          coach_id: [],
        };
        localStorage.setItem("bookingToExpire", JSON.stringify(bookingData));
      }
    } else {
      const savedData = localStorage.getItem("bookingToExpire");
      if (savedData) {
        const bookingToExpire = JSON.parse(savedData);
        expireBooking(bookingToExpire)
          .then(() => {
            localStorage.removeItem("bookingToExpire");
            props.onConfirm();
            props.onClose();
          })
          .catch((error) => {
            console.error("Failed to expire booking:", error);
            localStorage.removeItem("bookingToExpire");
          });
      }
    }

    return () => {
      localStorage.removeItem("bookingToExpire");
    };
  }, [props.showPopup, props.bookingToConfirm]);

  useBeforeUnload(
    "Din bokning kommer att avbrytas om du lämnar sidan.",
    props.showPopup,
    () => handleClose
  );

  const { coachesList, latestCoPlayers, coaches } = props;
  const [chosenCoPlayers, setChosenCoPlayers] = useState<IUser[]>([]);
  const [chosenCoach, setChosenCoach] = useState<string>("");
  const [availableCoPlayers, setAvailableCoPlayers] = useState<IUser[]>([]);
  const [guests, setGuests] = useState<string[]>([]);
  const [totalCoPlayers, setTotalCoPlayers] = useState<number>(0);
  const [totalPeopleInBooking, setTotalPeopleInBooking] = useState<number>(0);
  const { weekday, dateAndMonth } = formatDateToSwedish(props.filterDate);
  const [currentAdditiveChoice, setCurrentAdditiveChoice] =
    useState<IAdditiveOption>({ value: "CO-PLAYER" });
  const [currentAdditiveText, setCurrentAdditiveText] =
    useState<string>("Välj medspelare");
  const additiveOptions = [
    { label: "Medspelare", value: "CO-PLAYER", text: "Välj medspelare" },
    { label: "Tränare", value: "COACH", text: "Välj tränare" },
  ];
  const [countDown, setCountDown] = useState<number>(300);
  const clockColorClass = countDown < 60 ? "color-red" : "";
  const [coPlayerSlots, setCoPlayerSlots] = useState<{
    [key: string]: ISummaryItem;
  }>({});
  const [previousChosenCoPlayers, setPreviousChosenCoPlayers] = useState<
    IUser[]
  >([]);

  const handleConfirm = () => {
    localStorage.removeItem("bookingToExpire");
    setChosenCoPlayers([]);
    setGuests([]);
    setChosenCoach("");
    setCurrentAdditiveChoice({ value: "CO-PLAYER" });
    setCurrentAdditiveText("Välj medspelare");
    props.onConfirm();
    setCountDown(300);
    props.onClose();
  };

  useEffect(() => {
    setTotalCoPlayers(chosenCoPlayers.length + guests.length);
    setTotalPeopleInBooking(
      chosenCoPlayers.length + guests.length + (chosenCoach !== "" ? 1 : 0)
    );
  }, [guests, chosenCoPlayers, chosenCoach]);

  const handleCoPlayerSlot = async (
    datetime_start: string,
    member_id: string,
    court_id: string,
    court_type: ICourtType
  ) => {
    try {
      const response = await getSlotByMember(
        datetime_start,
        member_id,
        court_id,
        court_type
      );
      return response;
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (
      chosenCoPlayers.length > 0 &&
      previousChosenCoPlayers !== chosenCoPlayers
    ) {
      const promises = chosenCoPlayers.map((player) =>
        handleCoPlayerSlot(
          props.bookingToConfirm?.datetime_start || "",
          player.member_id,
          props.bookingToConfirm?.court_id || "",
          props.court.court_type as ICourtType
        ).then((response) => ({ [player.member_id]: response }))
      );
      Promise.all(promises).then((responses) => {
        const slots = responses.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setCoPlayerSlots((prev) => ({ ...prev, ...slots }));
      });
      setPreviousChosenCoPlayers(chosenCoPlayers);
    }
  }, [chosenCoPlayers]);

  useEffect(() => {
    setAvailableCoPlayers(latestCoPlayers);
  }, [latestCoPlayers, props.showPopup]);

  const handleClose = async () => {
    if (disableConfirmBtn) return;
    setCurrentAdditiveChoice({ value: "CO-PLAYER" });
    if (props.bookingToConfirm === undefined) return;
    const bookingToExpire: ICancelBookingInputType = {
      booking_id: props.bookingToConfirm.booking_id,
      datetime_start: props.bookingToConfirm.datetime_start,
      court_id: props.bookingToConfirm.court_id,
      slot_index: props.bookingToConfirm.slot_index,
      coach_id: [],
    };

    try {
      const response = await expireBooking(bookingToExpire);
      if (response) {
        handleConfirm();
        return;
      }
      toast.error("Något gick fel, försök igen");
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    let interval: any = null;
    const initialCountDown = 300; // Initial countdown time in seconds (5 minutes)

    // Function to update the countdown based on the actual elapsed time
    const tick = () => {
      const savedStartTime = Number(localStorage.getItem("countdownStartTime")); // Get the saved start time
      const elapsedTime = Math.floor((Date.now() - savedStartTime) / 1000); // Calculate elapsed time in seconds
      const remainingTime = Math.max(0, initialCountDown - elapsedTime); // Calculate the remaining time

      setCountDown(remainingTime); // Update the countdown state

      if (remainingTime <= 0) {
        clearInterval(interval);
        toast.error(
          "Din bokning avbröts för att inte ha slutförts inom 5 minuter."
        );
        handleClose();
        localStorage.removeItem("countdownStartTime"); // Clean up saved start time
      } else if (remainingTime === 60) {
        toast.warning("Vänligen avsluta din bokning inom 1 min");
      }
    };

    if (props.showPopup) {
      // Set the start time only if it's not already set
      if (!localStorage.getItem("countdownStartTime")) {
        localStorage.setItem("countdownStartTime", Date.now().toString());
      }
      interval = setInterval(tick, 1000); // Update the countdown every second
    } else {
      // Cleanup when the popup is closed
      localStorage.removeItem("countdownStartTime");
    }

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [props.showPopup]);

  useEffect(() => {
    if (countDown === 0) {
      handleClose();
    }
  }, [countDown]);

  const formatTime = () => {
    const minutes = Math.floor(countDown / 60);
    const seconds = countDown % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleAdditiveChoiceToggle = (value: IAdditiveOption) => {
    setCurrentAdditiveChoice(value);
    const selectedOption = additiveOptions.find(
      (option) => option.value === value.value
    );
    if (selectedOption) {
      setCurrentAdditiveText(selectedOption.text);
    }
  };

  const handleConfirmBooking = async () => {
    if (props.bookingToConfirm === undefined) return;
    if (totalPeopleInBooking < 1) {
      toast.error("Du måste lägga till minst en medspelare eller gäst");
      return;
    }
    setDisableConfirmBtn(true);
    const co_players: IIndividualWithStatus[] = chosenCoPlayers.map(
      (player) => ({
        member_id: player.member_id,
        status: StatusEnum.PENDING,
      })
    );

    const coach = chosenCoach !== "" ? [chosenCoach] : [];
    const guestsList = guests.map((guest) => ({
      member_id: guest,
      status: StatusEnum.PENDING,
    }));

    const bookingToConfirm: IConfirmBookingSpec = {
      booking_id: props.bookingToConfirm.booking_id,
      booking_type: props.bookingToConfirm.booking_type,
      court_id: props.bookingToConfirm.court_id,
      guests: guestsList,
      member_id: props.bookingToConfirm.member_id,
      member_status: props.bookingToConfirm.member_status,
      member_name: props.bookingToConfirm.member_name,
      slot_index: props.bookingToConfirm.slot_index,
      status: props.bookingToConfirm.status,
      datetime_start: props.bookingToConfirm.datetime_start,
      co_players: co_players,
      coach_id: coach,
      created_at: props.bookingToConfirm.created_at,
      created_by: props.bookingToConfirm.created_by,
    };
    const memberType =
      props.user?.signInUserSession.idToken.payload["cognito:groups"];

    if (memberType === undefined) {
      toast.error("Något gick fel, försök igen");
      setDisableConfirmBtn(false);
      return;
    }
    if (isJunior) {
      if (chosenCoach === "") {
        toast.error("Du måste välja en tränare");
        setDisableConfirmBtn(false);
        return;
      }
    }
    let bookingToast = "" as Id;
    try {
      bookingToast = toast.loading("Bokar tid...");
      const bookingConfirmResponse = await confirmBooking(
        bookingToConfirm,
        memberType[0]
      );
      if (bookingConfirmResponse && !("message" in bookingConfirmResponse)) {
        toast.success("Bokning bekräftad!");
        toast.dismiss(bookingToast);
        handleConfirm();
      } else {
        toast.dismiss(bookingToast);
      }
      setDisableConfirmBtn(false);
    } catch (error: any) {
      setDisableConfirmBtn(false);
      toast.dismiss(bookingToast);
      toast.error(error);
    }
  };

  const addCoach = (coach: string) => {
    setChosenCoach(coach);
    toast.success("Tränare vald");
  };
  const removeCoach = (coach: string) => () => {
    setChosenCoach("");
    toast.success("Tränare borttagen");
  };
  const addPlayerAsCoPlayer = (player: IUser, source: string) => {
    setSearchString("");
    setSearchedUsers([]);
    if (totalCoPlayers === 3) {
      toast.error("Du kan inte välja fler än 3 medspelare");
      return;
    }

    setChosenCoPlayers((prev) => [...prev, { ...player, source }]);

    toast.success(
      `Medspelare ${
        capitalizeWords(player.first_name) +
        " " +
        capitalizeWords(player.last_name)
      } tillagd`
    );
  };

  const addGuest = () => {
    setGuests((prev) => [...prev, "guest"]);
  };

  const removeCoPlayer = (player: any) => () => {
    setChosenCoPlayers((prev) =>
      prev.filter((p) => p.member_id !== player.member_id)
    );
    toast.success(
      `Medspelare ${
        capitalizeWords(player.first_name) +
        " " +
        capitalizeWords(player.last_name)
      } borttagen`
    );
  };

  const lastWarningTimeRef = useRef<number>(0);

  const showWarning = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeRef.current >= 5000) {
      lastWarningTimeRef.current = now;
      toast.warning("Inga användare hittades");
    }
  }, []);

  const fetchSearchUsers = async (search: string) => {
    try {
      const response = await searchUsers(search, "user", true);
      if (response.length === 0) {
        showWarning();
      }
      setSearchedUsers(response);
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (searchString.length > 0) {
      handleSearchUsers();
    } else {
      setSearchedUsers([]);
    }
  }, [searchString]);

  const handleSearchUsers = () => {
    fetchSearchUsers(searchString);
  };
  /** End search users  */

  const isReady = props.bookingToConfirm !== undefined;
  const chosenCoachData = chosenCoach
    ? coaches.find((c) => c.coach_id === chosenCoach)
    : null;

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={<span className="text-m">Din bokning</span>}
      loading={!isReady}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-top min-height-35px">
            {isReady && (
              <span className="text-m clock-wrapper">
                <img src={time} alt="clock"></img>
                <span className={clockColorClass}>{formatTime()}</span>
              </span>
            )}
            {isReady ? (
              <button
                onClick={() => {
                  if (!disableConfirmBtn) {
                    handleConfirmBooking();
                  }
                }}
              >
                Boka tid
              </button>
            ) : (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {weekday}, {dateAndMonth}
                  </span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text ">Tid + Bana</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{props.timeAndCourtString}</span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Huvudbokare</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {userString} ({props.bookingToConfirm?.member_id})
                  </span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
              <div className="general-popup-flex-between align-top">
                <span className="text-m primary-text">Medspelare</span>
                <span className="text-m primary-text text-svg-wrapper">
                  <div className="chosen-players">
                    {chosenCoPlayers.map((player, index) => {
                      const coPlayerPermission = props.permissions.find(
                        (permission) =>
                          permission.member_type === player.member_type
                      );

                      let coPlayerPrice = 0;
                      const coPlayerSlot = coPlayerSlots[player.member_id];
                      if (
                        (coPlayerPermission &&
                          coPlayerPermission.member_type === "Junior") ||
                        (coPlayerPermission &&
                          coPlayerPermission.member_type === "Junior B")
                      ) {
                        if (coPlayerSlot) {
                          coPlayerPrice = Number(coPlayerSlot.price);
                        }
                      } else if (
                        coPlayerSlot &&
                        coPlayerSlot.available_for_member_type
                      ) {
                        coPlayerPrice = 0;
                      } else if (props.price && coPlayerPermission) {
                        coPlayerPrice =
                          Number(props.price) -
                          (Number(props.price) *
                            coPlayerPermission?.percent_discount_outside_access) /
                            100;
                      }

                      return (
                        <div key={index} className="chosen-player">
                          {capitalizeWords(player.first_name)}{" "}
                          {capitalizeWords(player.last_name)} (
                          {player.member_id}) {Number(coPlayerPrice)} kr
                          <img
                            onClick={removeCoPlayer(player)}
                            src={singleCross}
                            alt="cross"
                            className="cross-img pointer"
                          ></img>
                        </div>
                      );
                    })}
                    {guests.map((guest, index) => {
                      let price = props.price;
                      price = props.price;

                      if (props.courtType === "OUTDOOR") {
                        price =
                          props.prices
                            .find((priceType) => priceType.color === "red")
                            ?.price.toString() || "";
                      }

                      return (
                        <div key={index} className="chosen-player">
                          Gäst {index + 1} - {price} kr
                          <img
                            onClick={() =>
                              setGuests((prev) =>
                                prev.filter((g, i) => i !== index)
                              )
                            }
                            src={singleCross}
                            alt="cross"
                            className="cross-img pointer"
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                </span>
              </div>
              {coachesList.length > 0 && (
                <div className="general-popup-flex-between">
                  <span className="text-m primary-text">Tränare</span>
                  <span className="text-m primary-text text-svg-wrapper">
                    {chosenCoachData && (
                      <div className="added-coach-remove-wrapper">
                        <span>
                          {capitalizeWords(chosenCoachData.first_name)}{" "}
                          {capitalizeWords(chosenCoachData.last_name)} (
                          {/* Bollmaksin har inte extra pris */}
                          {chosenCoachData.coach_id === "5513"
                            ? `${chosenCoachData.coach_price} kr`
                            : `${
                                chosenCoachData.coach_price +
                                chosenCoPlayers.length * 50
                              } kr`}
                          )
                        </span>
                        <img
                          onClick={removeCoach(chosenCoach)}
                          src={singleCross}
                          alt="cross"
                          className=" cross-img pointer"
                        ></img>
                      </div>
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="general-popup-lila-border-group">
            <div className="co-player-trainer-wrapper">
              <div className="user-booking-court-type">
                <div className="user-booking-text-toggle-wrapper">
                  <div className="user-bookings-toggle-text text-l">
                    {currentAdditiveText}
                  </div>
                  <div className="users-bookings-toggle-buttons">
                    {additiveOptions.map((option) => {
                      const trainersAvailale = coachesList.length > 0;

                      if (!trainersAvailale || guests.length > 0) return null;

                      return (
                        <div
                          key={option.value}
                          className="user-booking-additive-toggle-wrapper"
                        >
                          <button
                            className={`toggle-button text-m ${
                              currentAdditiveChoice.value === option.value
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleAdditiveChoiceToggle(
                                option as IAdditiveOption
                              )
                            }
                          >
                            {option.label}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {currentAdditiveChoice.value === "COACH" &&
                  coachesList.length > 0 &&
                  guests.length === 0 && (
                    <div className="coach-choices-wrapper text-m">
                      <div>
                        <span>Tillgängliga tränare</span>
                      </div>
                      {coachesList.map((coach, index) => {
                        const coachWidthData = coaches.find(
                          (c) => c.coach_id === coach
                        );
                        if (!coachWidthData) return;
                        return (
                          <div
                            key={index}
                            className="coach-wrapper pointer"
                            onClick={() => addCoach(coach)}
                          >
                            <div className="coach-name">
                              {capitalizeWords(coachWidthData.first_name)}{" "}
                              {capitalizeWords(coachWidthData.last_name)}
                            </div>
                            <div className="coach-price-id-wrapper">
                              <div className="coach-id">({coach})</div>
                              <div className="coach-price">
                                {coachWidthData.coach_price} kr
                              </div>
                            </div>
                            <a className="choose-coach"> Välj Coach</a>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {currentAdditiveChoice.value === "CO-PLAYER" && (
                  <div className="co-player-choices-wrapper text-m">
                    <div>
                      <span>Senaste medspelare</span>
                    </div>
                    {availableCoPlayers.length > 0 ? (
                      availableCoPlayers.map((player, index) => {
                        if (
                          chosenCoPlayers.some(
                            (coPlayer) =>
                              coPlayer.member_id === player.member_id
                          ) ||
                          player.member_id === props.bookingToConfirm?.member_id
                        ) {
                          return null;
                        }

                        if (index > 4) return null;
                        return (
                          <div
                            key={index}
                            className="player-wrapper pointer"
                            onClick={() =>
                              addPlayerAsCoPlayer(player, "available")
                            }
                          >
                            <div className="player-name">
                              {capitalizeWords(player.first_name)}{" "}
                              {capitalizeWords(player.last_name)}
                            </div>
                            <div className="player-id">
                              {" "}
                              {player.member_id}{" "}
                            </div>
                            <a className="choose-player"> Välj medspelare</a>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-latest-players secondary-text">
                        Du har inte spelat med någon tidigare
                      </div>
                    )}
                    <div>
                      <span>Övriga medspelare</span>
                    </div>
                    <div className="search-input-btn-wrapper">
                      <div className="search-input-wrapper">
                        <input
                          className="users-search-input text-m"
                          value={searchString}
                          onChange={(e) => setSearchString(e.target.value)}
                          placeholder="Sök på namn & medlemsnummer"
                        ></input>
                        <i className="fa-regular fa-magnifying-glass search-icon"></i>
                      </div>
                    </div>
                    {searchedUsers.length > 0 &&
                      searchedUsers
                        .filter(
                          (user) =>
                            !availableCoPlayers.some(
                              (coPlayer) =>
                                coPlayer.member_id === user.member_id
                            ) &&
                            !chosenCoPlayers.some(
                              (coPlayer) =>
                                coPlayer.member_id === user.member_id
                            ) &&
                            user.member_id !== props.bookingToConfirm?.member_id
                        )
                        .map((player, index) => {
                          if (index > 4) return null;

                          return (
                            <div
                              key={player.member_id}
                              className="player-wrapper pointer"
                              onClick={() =>
                                addPlayerAsCoPlayer(player, "filtered")
                              }
                            >
                              <div className="player-name">
                                <span>
                                  {capitalizeWords(player.first_name)}{" "}
                                  {capitalizeWords(player.last_name)}
                                </span>
                              </div>
                              <div className="player-id">
                                {player.member_id}
                              </div>
                              <a className="choose-player">Välj medspelare</a>
                            </div>
                          );
                        })}

                    <div className="add-guest-wrapper flex-row">
                      <button
                        onClick={() => addGuest()}
                        disabled={
                          totalCoPlayers === 3 || chosenCoach !== "" || isJunior
                        }
                      >
                        Lägg till gäst
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateBookingPopup;
