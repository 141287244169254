import "./UserBookings.scss";
import { useEffect, useState, useRef, RefObject } from "react";
import {
  IActivity,
  IBooking,
  ICancelBookingInputType,
  ICoPlayerBooking,
  ICoach,
  ICourt,
  ICourtType,
  IIndividualWithStatus,
  IInitiateBookingResponse,
  IInitiateBookingSpec,
  IPermission,
  IPrices,
  IQueueItem,
  IRehabBooking,
  IRehabSummary,
  ISummary,
  ISummaryItem,
  IUser,
} from "../../types/api";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import {
  capitalizeWords,
  formatDateToSwedish,
  hardCodeDateIsInRangeApr01Oktober22,
  hardcodeDateIsInRangeApr20Aug21,
} from "../../shared/utility";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { CognitoUser, WithCustomProperties } from "../../types/cognito";
import CreateBookingPopup from "../../containers/Bookings/CreateBookingPopup/CreateBookingPopup";
import { cancelBooking, initiateBooking } from "../../api/bookings/bookings";
import { BookingEnum, StatusEnum } from "../../shared/enums";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import { formatDate, formatToIsoDate } from "../../shared/dateUtils";
import { isBefore, startOfDay, parseISO, subDays, getDay } from "date-fns";
import Spinner from "../../components/UI/Spinner/Spinner";
import HandleConfirmationPopup from "../../containers/HandleConfirmation/HandleConfirmationPopup";
import EditCoPlayersPopup from "../../containers/Bookings/EditCoPlayersPopup/EditCoPlayersPopup";
import { getUserByMemberId } from "../../api/users/users";
import { addToQueue, removeFromQueue } from "../../api/queues/queues";
import { bookActivity, unbookActivity } from "../../api/activities/activities";
import BookRehabSlotPopup from "../../containers/Rehab/BookRehabSlotPopup/BookRehabSlotPopup";
import { cancelRehabBooking } from "../../api/rehab/rehab";
import { useMediaQuery } from "react-responsive";
import { getSlotByMember } from "../../api/summaries/summaries";

interface UserBookingsProps {}

function UserBookings(props: UserBookingsProps) {
  const makeBookingRef: RefObject<HTMLDivElement> = useRef(null);
  const [activitySlots, setActivitySlots] = useState<ISummaryItem[]>([]);
  const [showBookRehabPopup, setShowBookRehabPopup] = useState<boolean>(false);
  const [queueTimes, setQueueTimes] = useState<string[]>([]);
  const [rehabSummary, setRehabSummary] = useState<IRehabSummary>();
  const [permissions, setPermissions] = useState<IPermission[]>();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isBookingInitiated, setIsBookingInitiated] = useState(false);
  const isBookingInitiatedRef = useRef(isBookingInitiated);
  useEffect(() => {
    isBookingInitiatedRef.current = isBookingInitiated;
  }, [isBookingInitiated]);

  const [showRehabConfirmationPopup, setShowRehabConfirmationPopup] =
    useState<boolean>(false);
  const [rehabBookingToCancel, setRehabBookingToCancel] =
    useState<IRehabBooking>();
  const [queueTimeToBook, setQueueTimeToBook] = useState<string>("");
  const [showEditCoPlayersPopup, setShowEditCoPlayersPopup] =
    useState<boolean>(false);
  const [
    availableCoachesForEditCoPlayerSlot,
    setAvailableCoachesForEditCoPlayerSlot,
  ] = useState<string[]>([]);
  const [bookingToEditCoPlayers, setBookingToEditCoPlayers] =
    useState<IBooking>();
  const [coaches, setCoaches] = useState<ICoach[]>();
  const [currentSelectedCoach, setCurrentSelectedCoach] = useState<ICoach>();
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [userData, setUserData] = useState<IUser>();
  const [userQueueSpots, setUserQueueSpots] = useState<IQueueItem[]>([]);
  const [memberType, setMemberType] = useState<IPermission>();
  const [currentCourtType, setCurrentCourtType] =
    useState<ICourtType>("INDOOR");
  const isDesktop = useMediaQuery({ minWidth: 991 });
  const [prices, setPrices] = useState<IPrices[]>([]);
  const [timeAndCourtString, setTimeAndCourtString] = useState<string>("");
  const [cancelBookingWarningText, setCancelBookingWarningText] =
    useState<string>("Är du säker på att du vill avboka bokningen?");
  const [coPlayersData, setCoPlayersData] = useState<IUser[]>([]);
  const [bookingToCancel, setBookingToCancel] = useState<IBooking>();
  const [activityToCancel, setActivityToCancel] = useState<IActivity>();
  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false);
  const [showActivityConfirmationPopup, setShowActivityConfirmationPopup] =
    useState<boolean>(false);
  const [isCancelingBooking, setIsCancelingBooking] = useState<boolean>(false);
  const [isCancelingRehabBooking, setIsCancelingRehabBooking] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeBookingsForUser, setActiveBookingsForUser] = useState<
    IBooking[]
  >([]);
  const [activeBookingsAsCoPlayer, setActiveBookingsAsCoPlayer] = useState<
    ICoPlayerBooking[]
  >([]);

  const [activeActivitiesForUser, setActiveActivitiesForUser] = useState<
    IActivity[]
  >([]);
  const [activeRehabBookingsForUser, setActiveRehabBookingsForUser] = useState<
    IRehabBooking[]
  >([]);

  const [courts, setCourts] = useState<ICourt[]>([]);
  const [summaries, setSummaries] = useState<ISummary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [latestCoPlayers, setLatestCoPlayers] = useState<IUser[]>([]);
  const [filterDate, setFilterDate] = useState(() => {
    const storedDate = localStorage.getItem("filterDate");
    const now = new Date();
    return storedDate && new Date(storedDate) >= now
      ? new Date(storedDate)
      : now;
  });

  const { weekday, dateAndMonth } = formatDateToSwedish(filterDate);
  const [currentChosenSummaryItem, setCurrentChosenSummaryItem] =
    useState<ISummaryItem>();
  const [currentChosenCourt, setCurrentChosenCourt] = useState<ICourt>(
    courts[0]
  );
  const [typeOfSelectedItem, setTypeOfSelectedItem] = useState<
    "activity" | "booking"
  >("activity");
  const [bookingResponse, setBookingResponse] =
    useState<IInitiateBookingResponse>();

  const [showCreateBookingPopup, setShowCreateBookingPopup] =
    useState<boolean>(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error: any) => {
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    if (currentChosenSummaryItem) {
      setTimeAndCourtString(
        `${currentChosenSummaryItem?.start_time.substring(
          0,
          5
        )} - ${currentChosenSummaryItem?.end_time.substring(0, 5)}  ${
          currentChosenCourt.name
        }`
      );
    } else setTimeAndCourtString("");
  }, [currentChosenSummaryItem]);

  const handleFetchCoPlayersData = async (
    coPlayers: IIndividualWithStatus[]
  ) => {
    const response = await Promise.all(
      coPlayers.map(async (coPlayer) => {
        return await getUserByMemberId(coPlayer.member_id);
      })
    );
    setCoPlayersData((prev) => [...prev, ...response]);
  };
  useEffect(() => {
    if (activeBookingsForUser.length > 0) {
      activeBookingsForUser.forEach((booking) => {
        const coPlayers = booking.co_players;
        if (coPlayers.length > 0) {
          handleFetchCoPlayersData(coPlayers);
        }
      });
    }
  }, [activeBookingsForUser]);

  const handleFetchActivitySlot = async (
    datetime_start: string,
    member_id: string,
    court_id: string,
    court_type: ICourtType
  ) => {
    try {
      const response = await getSlotByMember(
        datetime_start,
        member_id,
        court_id,
        court_type
      );
      return response;
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (activeActivitiesForUser.length > 0 && userData) {
      const fetchActivitySlots = async () => {
        const slots = await Promise.all(
          activeActivitiesForUser.map(async (activity) => {
            const datetime_start = activity.datetime_start.replace(" ", "T");
            const response = await handleFetchActivitySlot(
              datetime_start,
              userData.member_id,
              activity.court_id[0],
              currentCourtType
            );
            return response;
          })
        );
        setActivitySlots(slots);
      };

      fetchActivitySlots();
    }
  }, [activeActivitiesForUser]);

  useEffect(() => {
    if (!hardCodeDateIsInRangeApr01Oktober22(filterDate)) {
      setCurrentCourtType("INDOOR");
    }
    localStorage.setItem("filterDate", formatToIsoDate(filterDate));
    setCurrentChosenSummaryItem(undefined);
  }, [filterDate]);

  const fetchChosenItems = async ({
    fetchActiveBookings = false,
    fetchUserData = false,
    fetchCourts = false,
    fetchPrices = false,
    fetchSlotsByMember = false,
    fetchLatestCoPlayers = false,
    fetchMemberType = false,
    fetchPermissions = false,
    fetchCoaches = false,
    fetchQueueSpots = false,
    fetchRehabSlots = false,
    fetchActiveActivities = false,
    fetchActiveRehabBookingsByMember = false,
    fetchActiveBookingsCoPlayer = false,
  }) => {
    setLoading(true);
    if (!user) return;
    setCurrentChosenSummaryItem(undefined);
    const memberType = user.attributes as WithCustomProperties;
    try {
      const response = await combinedQuery({
        requestor: "user",
        date: formatToIsoDate(filterDate),
        member_id: user.attributes["custom:member_id"],
        permission_id: memberType["custom:role"],
        court_type: currentCourtType,
        active: true,
        fetchUserData: fetchUserData,
        fetchCourts: fetchCourts,
        fetchPrices: fetchPrices,
        fetchActiveBookingsByMemberId: fetchActiveBookings,
        fetchLatestCoPlayers: fetchLatestCoPlayers,
        fetchMemberType: fetchMemberType,
        fetchPermissions: fetchPermissions,
        fetchCoaches: fetchCoaches,
        fetchSlotsByMember: fetchSlotsByMember,
        fetchQueueSpots: fetchQueueSpots,
        fetchRehabSlots: fetchRehabSlots,
        fetchActiveActivities: fetchActiveActivities,
        fetchActiveRehabBookingsByMember: fetchActiveRehabBookingsByMember,
        fetchActiveBookingsCoPlayer: fetchActiveBookingsCoPlayer,
      });

      if (
        fetchUserData &&
        response.userData &&
        !("message" in response.userData)
      ) {
        setUserData(response.userData);
      }
      if (
        fetchActiveActivities &&
        response.activeActivities &&
        !("message" in response.activeActivities)
      ) {
        setActiveActivitiesForUser(response.activeActivities);
      }
      if (fetchPrices && response.prices && !("message" in response.prices)) {
        setPrices(response.prices);
      }

      if (
        fetchPermissions &&
        response.permissions &&
        !("message" in response.permissions)
      ) {
        setPermissions(response.permissions);
      }
      if (
        fetchMemberType &&
        response.memberType &&
        !("message" in response.memberType)
      ) {
        setMemberType(response.memberType);
      }
      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }
      if (
        fetchLatestCoPlayers &&
        response.latestCoPlayers &&
        !("message" in response.latestCoPlayers)
      ) {
        setLatestCoPlayers(response.latestCoPlayers);
      }
      if (
        fetchRehabSlots &&
        response.rehabSlots &&
        !("message" in response.rehabSlots)
      ) {
        setRehabSummary(response.rehabSlots);
      }
      if (
        fetchSlotsByMember &&
        response.slotsByMember &&
        !("message" in response.slotsByMember)
      ) {
        setSummaries(response.slotsByMember);
      }
      if (
        fetchActiveBookings &&
        response.activeBookings &&
        !("message" in response.activeBookings)
      ) {
        setActiveBookingsForUser(response.activeBookings);
      }

      if (
        fetchCoaches &&
        response.coaches &&
        !("message" in response.coaches)
      ) {
        setCoaches(response.coaches);
      }
      if (
        fetchQueueSpots &&
        response.queueSpots &&
        !("message" in response.queueSpots)
      ) {
        setUserQueueSpots(response.queueSpots);
      }
      if (
        response.activeRehabBookings &&
        !("message" in response.activeRehabBookings)
      ) {
        setActiveRehabBookingsForUser(response.activeRehabBookings);
      }
      if (
        fetchActiveBookingsCoPlayer &&
        response.activeBookingsCoPlayer &&
        !("message" in response.activeBookingsCoPlayer)
      ) {
        setActiveBookingsAsCoPlayer(response.activeBookingsCoPlayer);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  const processQueueTimes = (slots: ISummaryItem[]): string[] => {
    const slotTimes: { [key: string]: ISummaryItem[] } = {};
    const now = new Date();

    slots.forEach((slot) => {
      if (!slotTimes[slot.start_time]) {
        slotTimes[slot.start_time] = [];
      }
      slotTimes[slot.start_time].push(slot);
    });

    const activeQueueSpotsForUserTimes = userQueueSpots.map((spot) =>
      spot.datetime_start.split("T")[1].substring(0, 8)
    );
    const memberId = user?.attributes["custom:member_id"];

    const queueTimes: string[] = Object.entries(slotTimes).reduce(
      (acc: string[], [startTime, slots]) => {
        const allTaken = slots.every(
          (slot) =>
            (slot.booking.length > 0 &&
              slot.booking[0].status !== "CANCELLED" &&
              slot.booking[0].status !== "SEMI_NO_SHOW" &&
              slot.booking[0] !== null &&
              slot.booking[0].member_id !== memberId) ||
            slot.activity !== null
        );

        const slotDateTime = new Date(
          `${formatDate(filterDate, "yyyy-MM-dd")}T${startTime}`
        );

        if (activeQueueSpotsForUserTimes.includes(startTime)) {
          return acc;
        }

        if (filterDate <= now && slotDateTime <= now) {
          return acc;
        }

        if (allTaken) {
          acc.push(startTime);
        }

        return acc;
      },
      []
    );

    const sortedQueueTimes = queueTimes.sort((a, b) => {
      const dateA = new Date(`${formatDate(filterDate, "yyyy-MM-dd")}T${a}`);
      const dateB = new Date(`${formatDate(filterDate, "yyyy-MM-dd")}T${b}`);
      return dateA.getTime() - dateB.getTime();
    });

    return sortedQueueTimes;
  };

  const handleAddToQueue = async (time: string) => {
    let timeToQueue = time;
    setQueueTimeToBook("");
    const memberId = user?.attributes["custom:member_id"];
    const datetime_start =
      formatDate(filterDate, "yyyy-MM-dd") + "T" + timeToQueue;
    if (!memberId || !datetime_start) return;
    try {
      const response = await addToQueue(
        memberId,
        datetime_start,
        currentCourtType
      );
      if (response && "message" in response === false) {
        toast.success("Tillagd i väntelista för " + time);
        fetchChosenItems({ fetchQueueSpots: true, fetchSlotsByMember: true });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  const handleRemoveFromQueue = async (
    datetime_start: string,
    member_id: string
  ) => {
    try {
      const response = await removeFromQueue(datetime_start, member_id);
      if (response && "message" in response === false) {
        toast.success("Tog bort från väntelista");
        fetchChosenItems({ fetchQueueSpots: true, fetchSlotsByMember: true });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!summaries) {
      return;
    }
    const slots = summaries.flatMap((summary) => summary.slots);
    const queueTimes = processQueueTimes(slots);
    setQueueTimes(queueTimes);
  }, [summaries]);

  useEffect(() => {
    if (queueTimeToBook !== "") {
      handleAddToQueue(queueTimeToBook);
    }
  }, [queueTimeToBook]);

  useEffect(() => {
    let firstRun = true;

    const fetchItemsIfNeeded = () => {
      if (!isBookingInitiatedRef.current) {
        fetchChosenItems({ fetchSlotsByMember: true });
      }

      timeoutRef.current = setTimeout(fetchItemsIfNeeded, 300000);
    };

    const startTimeout = () => {
      timeoutRef.current = setTimeout(fetchItemsIfNeeded, 300000);
    };

    if (firstRun) {
      firstRun = false;
      startTimeout();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [filterDate, currentCourtType, user]);

  useEffect(() => {
    if (user) {
      fetchChosenItems({
        fetchActiveBookings: true,
        fetchUserData: true,
        fetchSlotsByMember: true,
        fetchCourts: true,
        fetchPrices: true,
        fetchLatestCoPlayers: true,
        fetchMemberType: true,
        fetchCoaches: true,
        fetchQueueSpots: true,
        fetchActiveActivities: true,
        fetchPermissions: true,
        fetchActiveBookingsCoPlayer: true,
      });
    }
  }, [user]);

  useEffect(() => {
    if (!userData) return;
    let fetch = false;
    if (userData.rehab_access) {
      fetch = true;
    }
    fetchChosenItems({
      fetchActiveRehabBookingsByMember: fetch,
    });
  }, [userData]);

  useEffect(() => {
    if (!user) return;
    fetchChosenItems({
      fetchSlotsByMember: true,
    });
  }, [filterDate, currentCourtType]);

  const checkCanHaveAnotherBooking = (datetime_start: string) => {
    if (!memberType || !currentChosenSummaryItem) return false;
    const today = new Date();

    const [extraHours, extraMinutes, extraSeconds] =
      memberType.extra_booking_time.split(":");

    let allBookings = 0;
    activeBookingsForUser.forEach(() => {
      allBookings++;
    });
    activeActivitiesForUser.forEach(() => {
      allBookings++;
    });
    if (allBookings === 0) return true;
    if (allBookings > 1) {
      return false;
    }
    if (allBookings === 1 && memberType.extra_booking_time === "23:59:00") {
      return false;
    } else if (
      allBookings === 1 &&
      memberType.extra_booking_time !== "23:59:00"
    ) {
      const [hours, minutes, seconds] = datetime_start.split(":");

      const selectedBookingDate = new Date(filterDate);
      selectedBookingDate.setHours(
        Number(hours),
        Number(minutes),
        Number(seconds),
        0
      );
      const todayExtraBookingTime = new Date(today);
      todayExtraBookingTime.setHours(
        Number(extraHours),
        Number(extraMinutes),
        Number(extraSeconds),
        0
      );

      const tomorrow = subDays(today, -1);
      tomorrow.setHours(23, 59, 0, 0);
      const todayLateBookingTime = new Date(today);
      todayLateBookingTime.setHours(23, 59, 0, 0);
    
      if (today > todayExtraBookingTime && selectedBookingDate < tomorrow) {
        return true;
      }
      if (
        today < todayExtraBookingTime &&
        selectedBookingDate < todayLateBookingTime
      ) {
        return true;
      }
    }
    if (allBookings === 1 && activeBookingsForUser.length > 0) {
      //case1
      const booking = activeBookingsForUser[0];
      const bookingDate = parseISO(booking.datetime_start);
      const oneDayBeforeBooking = subDays(bookingDate, 1);

      const extraBookingTimeDayBeforeBooking = new Date(oneDayBeforeBooking);
      extraBookingTimeDayBeforeBooking.setHours(
        Number(extraHours),
        Number(extraMinutes),
        Number(extraSeconds),
        0
      );
      if (extraBookingTimeDayBeforeBooking < today) return true;
    } else if (allBookings === 1 && activeActivitiesForUser.length > 0) {
      const activity = activeActivitiesForUser[0];
      const activityDate = parseISO(activity.datetime_start);
      const oneDayBeforeActivity = subDays(activityDate, 1);

      const extraBookingTimeDayBeforeBooking = new Date(oneDayBeforeActivity);

      extraBookingTimeDayBeforeBooking.setHours(
        Number(extraHours),
        Number(extraMinutes),
        Number(extraSeconds),
        0
      );
      if (extraBookingTimeDayBeforeBooking < today) return true;
    }
    return false;
  };

  const handleUnbookBooking = async (booking: IBooking) => {
    setIsCancelingBooking(true);

    setBookingToCancel(undefined);
    const coachToCancle = booking.coach_id.length > 0 ? booking.coach_id : [];
    const bookingToCancel: ICancelBookingInputType = {
      court_id: booking.court_id,
      datetime_start: booking.datetime_start,
      booking_id: booking.booking_id,
      slot_index: booking.slot_index,
      coach_id: coachToCancle,
    };
    try {
      const cancelBookingResponse = await cancelBooking(
        bookingToCancel,
        "user"
      );
      if (cancelBookingResponse) {
        toast.success("Bokning avbokad");
        fetchChosenItems({
          fetchActiveBookings: true,
          fetchSlotsByMember: true,
        });
        setIsCancelingBooking(false);
      }
      setShowConfirmationPopup(false);
    } catch (error: any) {
      setShowConfirmationPopup(false);
      toast.error(error);
    }
  };
  const handleUnbookActivity = async (activity: IActivity) => {
    setActivityToCancel(undefined);
    if (!userData) return;
    const formatedDateTimeStart = activity.datetime_start.replace(" ", "T");
    try {
      const unbookActivityResponse = await unbookActivity(
        activity.court_id[0],
        formatedDateTimeStart,
        userData?.member_id,
        "user"
      );
      if (unbookActivityResponse) {
        toast.success("Aktivitet avbokad");
        fetchChosenItems({
          fetchActiveActivities: true,
          fetchSlotsByMember: true,
        });
      }
      setShowActivityConfirmationPopup(false);
    } catch (error: any) {
      setShowActivityConfirmationPopup(false);
      toast.error(error);
    }
  };

  const handleCancelRehabBooking = async (rehabBooking: IRehabBooking) => {
    setRehabBookingToCancel(undefined);
    setIsCancelingRehabBooking(true);
    try {
      const response = await cancelRehabBooking(rehabBooking);
      if (response && !("messsage" in response)) {
        setIsCancelingRehabBooking(false);
        toast.success("Rehab avbokad");
        fetchChosenItems({
          fetchActiveRehabBookingsByMember: true,
          fetchRehabSlots: true,
        });
      }
      setShowRehabConfirmationPopup(false);
    } catch (error: any) {
      setIsCancelingRehabBooking(false);
      toast.error(error);
      setShowRehabConfirmationPopup(false);
    }
  };

  const handleBookActivity = async () => {
    if (!user || !currentChosenSummaryItem) return;
    const member_id = user.attributes["custom:member_id"];
    const datetime_start =
      formatDate(filterDate, "yyyy-MM-dd") +
      "T" +
      currentChosenSummaryItem.start_time;
    const court_id = currentChosenCourt.court_id;
    setIsLoading(true);
    try {
      const response = await bookActivity(
        member_id,
        court_id,
        datetime_start,
        "user"
      );
      if (response && !("message" in response)) {
        toast.success("Bokning skapad");
        fetchChosenItems({
          fetchActiveActivities: true,
          fetchSlotsByMember: true,
        });
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error);
    }
  };
  const scrollToMakeBooking = () => {
    if (makeBookingRef.current) {
      makeBookingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToBookings = () => {
    if (isDesktop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      scrollToMakeBooking();
    }
  };
  const handleInitiateBooking = async () => {
    if (!user || !currentChosenSummaryItem) return;

    setIsBookingInitiated(true);

    setShowCreateBookingPopup(true);
    const dateTimeString =
      formatDate(filterDate, "yyyy-MM-dd") +
      "T" +
      currentChosenSummaryItem.start_time;
    const bookingToInitiate: IInitiateBookingSpec = {
      court_id: currentChosenCourt.court_id,
      datetime_start: dateTimeString,
      member_id: user.attributes["custom:member_id"],
      booking_type: BookingEnum.CONTRACT,
    };

    try {
      const initiateBookingResponse = await initiateBooking(bookingToInitiate);
      if (initiateBookingResponse && !("message" in initiateBookingResponse)) {
        setBookingResponse(initiateBookingResponse);
      } else {
        setShowCreateBookingPopup(false);
        setIsBookingInitiated(false);
      }
    } catch (error: any) {
      setIsBookingInitiated(false);
      toast.error(error);
      setShowCreateBookingPopup(false);
    }
  };

  const handleConfirmFromBooking = () => {
    setCurrentSelectedCoach(undefined);
    fetchChosenItems({
      fetchActiveBookings: true,
      fetchSlotsByMember: true,
    });
    setBookingResponse(undefined);
  };

  const handleSlotClick = (
    slot: any,
    court: ICourt,
    type: "activity" | "booking"
  ) => {
    scrollToBookings();
    setTypeOfSelectedItem(type);
    setCurrentChosenSummaryItem(slot);
    setCurrentChosenCourt(court);
  };

  const handleToggleCourtType = (type: "INDOOR" | "OUTDOOR") => {
    setCurrentCourtType(type);
  };
  const openConfirmationPopup = (booking: IBooking) => {
    setBookingToCancel(booking);
    const currentDate = new Date();
    const bookingDateTime = new Date(booking.datetime_start);
    const oneHourBeforeBooking = new Date(
      bookingDateTime.getTime() - 60 * 60 * 1000
    );

    const formattedCurrentDate = formatDate(currentDate, "yyyy-MM-dd");
    const formattedCurrentTime = formatDate(currentDate, "HH:mm:ss");

    const formattedOneHourBeforeBookingDate = formatDate(
      oneHourBeforeBooking,
      "yyyy-MM-dd"
    );
    const formattedOneHourBeforeBookingTime = formatDate(
      oneHourBeforeBooking,
      "HH:mm:ss"
    );

    const formattedBookingDate = formatDate(bookingDateTime, "yyyy-MM-dd");
    const formattedBookingTime = formatDate(bookingDateTime, "HH:mm:ss");

    const currentDateTime = `${formattedCurrentDate}T${formattedCurrentTime}`;
    const oneHourBeforeBookingDateTime = `${formattedOneHourBeforeBookingDate}T${formattedOneHourBeforeBookingTime}`;
    const bookingDateTimeFormatted = `${formattedBookingDate}T${formattedBookingTime}`;

    if (
      currentDateTime >= oneHourBeforeBookingDateTime &&
      currentDateTime <= bookingDateTimeFormatted
    ) {
      setCancelBookingWarningText(
        "Är du säker på att du vill avboka denna tid? Du kommer att få en no-show om ingen annan bokar tiden"
      );
    } else {
      setCancelBookingWarningText(
        "Är du säker på att du vill avboka bokningen?"
      );
    }
    setShowConfirmationPopup(true);
  };

  const openActivityConfirmationPopup = (booking: IActivity) => {
    setActivityToCancel(booking);
    setShowActivityConfirmationPopup(true);
  };

  const openRehabConfirmationPopup = (booking: IRehabBooking) => {
    setRehabBookingToCancel(booking);
    setShowRehabConfirmationPopup(true);
  };

  const openEditCoPlayersPopup = (booking: IBooking) => {
    setBookingToEditCoPlayers(booking);

    // Find the summary that matches the booking's court_id
    const summaryForCourt = summaries.find(
      (summary) => summary.court_id === booking.court_id
    );

    // If the summary is found, find the specific slot that matches the booking's start time
    const editCoplayersSlot = summaryForCourt?.slots.find(
      (slot) => slot.start_time === booking.datetime_start.slice(11, 19)
    );
    const coachesList = editCoplayersSlot
      ? editCoplayersSlot.coach_available
      : [];

    setAvailableCoachesForEditCoPlayerSlot(coachesList);
    // Pass the coachesList to the EditCoPlayersPopup
    setShowEditCoPlayersPopup(true);
  };

  const checkChosenSlot = (slot: any, court: ICourt) => {
    return (
      slot.start_time === currentChosenSummaryItem?.start_time &&
      court.court_id === currentChosenCourt.court_id
    );
  };

  useEffect(() => {});

  const renderSlotRow = (slot: ISummaryItem, index: number, court: ICourt) => {
    const isFrozen = userData?.frozen || false;
    const time = slot.start_time.substring(0, 5);
    const isAvailable = slot.available_for_member_type === true;
    const hasBooking =
      slot.booking.length > 0 &&
      slot.booking[0].status !== StatusEnum.SEMI_NO_SHOW;
    const currentDateTime = new Date();
    const activity = slot.activity;
    const hasActivity = activity !== null;
    const currentDateStart = startOfDay(currentDateTime);
    const filterDateStart = startOfDay(filterDate);
    const slotStartTime = new Date(
      `${formatDate(filterDate, "yyyy-MM-dd")}T${slot.start_time}`
    );

    const isInPast =
      isBefore(slotStartTime, currentDateTime) ||
      isBefore(filterDateStart, currentDateStart);

    const textColor =
      isAvailable && !hasBooking && !isInPast
        ? "primary-text"
        : "secondary-text";

    let selectedClass = "";
    let matchesSearchString = false;
    if (currentSelectedCoach) {
      matchesSearchString =
        (slot.coach_available.find(
          (coach) => coach === currentSelectedCoach.coach_id
        ) !== undefined &&
          isAvailable &&
          !isInPast &&
          !hasActivity) ||
        (hasActivity &&
          slot.activity.coach_id &&
          slot.activity.coach_id.some(
            (coach) => coach === currentSelectedCoach.coach_id
          ));
    }
    const matchesClass = matchesSearchString
      ? "matches-search"
      : currentSelectedCoach !== undefined
      ? "no-match"
      : "";
    if (checkChosenSlot(slot, court)) {
      selectedClass = "selected-slot";
    }
    if (slot.end_time > "22:00:00") {
      return null;
    }
    if (
      court.court_type === "OUTDOOR" &&
      slot.start_time >= "20:00:00" &&
      !hardcodeDateIsInRangeApr20Aug21(filterDate) &&
      !hasBooking
    ) {
      return null; // Skip rendering this slot
    }
    if (loading) {
      return (
        <tr key={index} className={textColor}>
          <td>
            <div className={`summary-item-wrapper `}>
              <div className="text-m summary-content-wrapper"></div>
            </div>
          </td>
        </tr>
      );
    }
    if (isInPast) {
      return (
        <tr key={index} className={textColor}>
          <td>
            <div className={`summary-item-wrapper not-available `}>
              <div className="text-m">{`${time}`}</div>
            </div>
          </td>
        </tr>
      );
    }

    if (hasBooking && !hasActivity) {
      if (
        (slot.booking[0].member_id === user?.attributes["custom:member_id"] ||
          (slot.booking[0].co_players &&
            slot.booking[0].co_players.some(
              (coPlayer) =>
                coPlayer.member_id === user?.attributes["custom:member_id"]
            ))) &&
        (slot.booking[0].status === StatusEnum.CHECKED_IN ||
          slot.booking[0].status === StatusEnum.CONFIRMED)
      ) {
        return (
          <tr key={index}>
            <td>
              <div
                className={`summary-item-wrapper ${matchesClass} slot-booked`}
              >
                <div className="text-m summary-content-wrapper">
                  <div className="text-m">{`${time}`}</div>
                  <span>Bokad</span>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    }

    if (hasActivity && activity.hidden === false) {
      const activityName = activity.name.split("%-%")[0];
      const truncatedName =
        activityName.length > 9
          ? `${activityName.substring(0, 8)}..`
          : activityName;

      if (
        slot.activity.participants &&
        slot.activity.participants.some(
          (participant) => participant.member_id === userData?.member_id
        )
      ) {
        return (
          <tr key={index}>
            <td>
              <div
                className={`summary-item-wrapper ${activity.color} ${matchesClass}`}
              >
                <div className="text-m summary-content-wrapper">
                  <div className="text-m">{`${time}`}</div>
                  <span>Bokad</span>
                  <span>{truncatedName}</span>
                </div>
              </div>
            </td>
          </tr>
        );
      }
      if (isAvailable)
        return (
          <tr key={index}>
            <td>
              <div
                className={`summary-item-wrapper ${activity.color} ${matchesClass} ${selectedClass} pointer`}
                onClick={() => handleSlotClick(slot, court, "activity")}
              >
                <div className="text-m summary-content-wrapper">
                  <div className="text-m">{`${time}`}</div>
                  <span>{truncatedName}</span>
                </div>
              </div>
            </td>
          </tr>
        );
    } else if (hasActivity && activity.hidden === true) {
      return (
        <tr key={index} className={textColor}>
          <td>
            <div className={`summary-item-wrapper not-available `}>
              <div className="text-m">{`${time}`}</div>
            </div>
          </td>
        </tr>
      );
    }

    const coachAvailable = slot.coach_available.length > 0;

    return (
      <tr key={index} className={textColor}>
        <td>
          {!isAvailable || hasBooking ? (
            <div className={`summary-item-wrapper not-available `}>
              <div className="text-m">{`${time}`}</div>
            </div>
          ) : (
            <div
              className={`summary-item-wrapper ${selectedClass} ${matchesClass} is-available pointer`}
              onClick={() => handleSlotClick(slot, court, "booking")}
            >
              <div className="text-m summary-content-wrapper">{`${time}`}</div>
              <div className="text-m">
                {checkChosenSlot(slot, court) ? (
                  <div> Vald tid</div>
                ) : (
                  coachAvailable && <i className="fa-regular fa-user"></i>
                )}
              </div>
            </div>
          )}
        </td>
      </tr>
    );
  };

  const renderCourtColumn = (court: ICourt) => {
    const summaryForCourt = summaries.find(
      (summary) => summary.court_id === court.court_id
    );

    if (!summaryForCourt || (!summaryForCourt.slots && !loading)) {
      return (
        <td key={court.court_id}>
          <table className="nested-courts-table">
            <tbody>
              <tr>
                <td>
                  <div className="summary-item-wrapper">
                    <div className="text-m">Inga tider</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      );
    }

    return (
      <td key={court.court_id}>
        <table className="nested-courts-table">
          <tbody>
            {summaryForCourt.slots.map((slot, index) =>
              renderSlotRow(slot, index, court)
            )}
          </tbody>
        </table>
      </td>
    );
  };

  if (!courts || !summaries || !coaches || !permissions || !userData) {
    return <Spinner />;
  }

  return (
    <div className="user-bookings-page-container">
      <div className="user-bookings-layout">
        <div className="user-bookings-declarations">
          <h1 className="text-l primary-text">Översikt</h1>
          <div className="declarations-wrapper text-m">
            <span className="declaration"> Bokat (Grått)</span>
            <span className="declaration"> Ledigt (Vitt)</span>
            <span className="declaration">
              <i className="fa-regular fa-user"></i> Tränare tillgänglig
            </span>
          </div>
        </div>

        <div className="bookings-action-wrapper">
          <div className="bookings-weekday-selector-wrapper">
            <div className="user-booking-court-type text-m">
              <button
                className={`toggle-button ${
                  currentCourtType === "INDOOR" ? "active" : ""
                }`}
                onClick={() => handleToggleCourtType("INDOOR")}
              >
                Inne
              </button>
              {hardCodeDateIsInRangeApr01Oktober22(filterDate) && (
                <button
                  className={`toggle-button ${
                    currentCourtType === "OUTDOOR" ? "active" : ""
                  }`}
                  onClick={() => handleToggleCourtType("OUTDOOR")}
                >
                  Ute
                </button>
              )}
            </div>
            <WeekDaySelector
              value={filterDate}
              onChange={setFilterDate}
              includeWeekdays={isDesktop}
              textM
            />
            {filterDate.getDate() !== new Date().getDate() && (
              <a className="text-m" onClick={() => setFilterDate(new Date())}>
                Till idag
              </a>
            )}
          </div>
          <div className="bookings-add-wrapper">
            <select
              className="coaches-select text-m"
              value={currentSelectedCoach ? currentSelectedCoach.coach_id : ""}
              onChange={(e) => {
                const selectedCoach = coaches.find(
                  (coach) => coach.coach_id === e.target.value
                );
                setCurrentSelectedCoach(selectedCoach || undefined);
              }}
            >
              <option value="">Välj tränare</option>
              {coaches.map((coach, index) => {
                const name =
                  capitalizeWords(coach.first_name) +
                  " " +
                  capitalizeWords(coach.last_name);
                return (
                  <option key={index} value={coach.coach_id}>
                    {name}
                  </option>
                );
              })}
            </select>
            {queueTimes.length > 0 && (
              <select
                className="queue-times-select text-m"
                value={""}
                onChange={(e) => setQueueTimeToBook(e.target.value)}
              >
                <option value="" disabled>
                  Väntelista
                </option>
                {queueTimes.map((time, index) => {
                  return (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  );
                })}
              </select>
            )}
            {userData && userData.rehab_access === true && (
              <button
                className="text-m"
                onClick={() => {
                  fetchChosenItems({
                    fetchRehabSlots: true,
                  });
                  setShowBookRehabPopup(true);
                }}
              >
                <span className="desktop-text">Boka Rehab-bad</span>
                <span className="mobile-text">Rehab-bad</span>
              </button>
            )}
          </div>
        </div>
        <div className="mobile-info-wrapper">
          <span className="text-m">Välj tillgänglig tid för din bokning</span>
        </div>
        <div className="courts-table-overflow-wrapper">
          <table className="courts-table">
            <thead>
              <tr>
                {courts.map((court, index) => {
                  if (court.court_type === currentCourtType)
                    return (
                      <th key={index} className="text-m secondary-text">
                        {court.name} {court.name === "B27" ? "(hardcourt)" : ""}
                      </th>
                    );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {courts.map((court, index) => {
                  if (court.court_type === currentCourtType)
                    return renderCourtColumn(court);
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="user-booking-maker-wrapper" ref={makeBookingRef}>
        <div className="user-booking-top-wrapper ">
          <div className="user-booking-heading text-m">
            {currentChosenSummaryItem ? (
              <span>Gå vidare med bokning</span>
            ) : (
              <span>Välj tid på en bana för att boka</span>
            )}
          </div>
          <div
            className={`user-booking-booking-type ${
              currentChosenSummaryItem ? "selected" : ""
            }`}
          >
            <div className="user-booking-specifics">
              <div className="user-booking-info-row-wrapper">
                <span className="text-m primary-text">Datum</span>
                <span className="text-m primary-text">
                  {weekday}, {dateAndMonth}
                </span>
              </div>
              <div className="user-booking-info-row-wrapper">
                <span className="text-m primary-text">Tid + Bana</span>
                <span className="text-m primary-text">
                  {timeAndCourtString}
                </span>
              </div>
              <div className="user-booking-info-row-wrapper">
                <span className="text-m primary-text">Huvudbokare</span>
                <span className="text-m primary-text">
                  {capitalizeWords(userData?.first_name || "")}{" "}
                  {capitalizeWords(userData?.last_name || "")} (
                  {userData?.member_id})
                </span>
              </div>
              {typeOfSelectedItem === "activity" &&
                currentChosenSummaryItem && (
                  <>
                    <div className="user-booking-info-row-wrapper">
                      <span className="text-m primary-text">Aktivitet</span>
                      <span className="text-m primary-text">
                        {
                          currentChosenSummaryItem?.activity?.name.split(
                            "%-%"
                          )[0]
                        }
                      </span>
                    </div>
                    {currentChosenSummaryItem?.activity?.participants_limit !==
                      0 && (
                      <>
                        <div className="user-booking-info-row-wrapper">
                          <span className="text-m primary-text">Pris:</span>
                          <span className="text-m primary-text">
                            {currentChosenSummaryItem?.activity?.price} kr
                          </span>
                        </div>
                        <div className="user-booking-info-row-wrapper">
                          <span className="text-m primary-text">Deltagare</span>
                          <span className="text-m primary-text">
                            {
                              currentChosenSummaryItem?.activity?.participants
                                .length
                            }{" "}
                            /{" "}
                            {
                              currentChosenSummaryItem?.activity
                                ?.participants_limit
                            }
                          </span>
                        </div>
                        {currentChosenSummaryItem?.activity?.coach_id.length >
                          0 &&
                          currentChosenSummaryItem?.activity?.coach_id[0] !==
                            "" && (
                            <div className="user-booking-info-row-wrapper">
                              <span className="text-m primary-text">
                                Tränare
                              </span>
                              <span className="text-m primary-text">
                                {currentChosenSummaryItem?.activity?.coach_id.map(
                                  (coach) => {
                                    const coachData = coaches.find(
                                      (c) => c.coach_id === coach
                                    );
                                    return (
                                      <span key={coach}>
                                        {capitalizeWords(
                                          coachData?.first_name || ""
                                        )}{" "}
                                        {capitalizeWords(
                                          coachData?.last_name || ""
                                        )}{" "}
                                        ({coach})
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          )}
                      </>
                    )}
                  </>
                )}

              {typeOfSelectedItem === "activity" &&
                currentChosenSummaryItem &&
                currentChosenSummaryItem?.activity?.comment && (
                  <div className="user-booking-info-row-wrapper flex-down ">
                    <span className="text-m primary-text">Kommentar:</span>
                    <span className="text-m primary-text">
                      {currentChosenSummaryItem?.activity?.comment}
                    </span>
                  </div>
                )}

              <div className="user-booking-info-row-wrapper text-m">
                {(() => {
                  const permission = permissions.find(
                    (permission) =>
                      permission.member_type === userData.member_type
                  );
                  if (userData.frozen) {
                    return (
                      <div>
                        <span className="text-m text-danger">
                          Ditt medlemskap är fryst och du kan inte boka
                        </span>
                      </div>
                    );
                  }
                  const isWeekday = (date: Date) => {
                    const day = getDay(date);
                    return day >= 1 && day <= 5; // Monday to Friday
                  };

                  const isBetween15And19 = (date: Date) => {
                    const hour = date.getHours();
                    return hour >= 15 && hour < 19;
                  };

                  const checkDate = (date: string) => {
                    const parsedDate = parseISO(date);
                    return (
                      isWeekday(parsedDate) && isBetween15And19(parsedDate)
                    );
                  };
                  if (!currentChosenSummaryItem) return;
                  const isOutsideJuniorTime = checkDate(
                    formatDate(filterDate, "yyyy-MM-dd") +
                      "T" +
                      currentChosenSummaryItem?.start_time
                  );

                  if (
                    (typeOfSelectedItem === "activity" &&
                      currentChosenSummaryItem.activity.members_with_access
                        .length === 0) ||
                    (typeOfSelectedItem === "activity" &&
                      currentChosenSummaryItem.activity.participants_limit ===
                        0)
                  ) {
                    return (
                      <span className="text-m text-danger">
                        Denna aktivitet kan inte bokas
                      </span>
                    );
                  }
                  if (
                    permission &&
                    (permission.member_type === "Junior" ||
                      permission.member_type === "Junior B" ||
                      permission.member_type === "Junior spelrätt ") &&
                    activeBookingsForUser.length > 0
                  ) {
                    return (
                      <span className="text-m text-danger">
                        Du som har medlemskap "{permission.member_type}" kan
                        bara ha en bokning åt gången
                      </span>
                    );
                  } else if (
                    permission &&
                    (permission.member_type === "Junior" ||
                      permission.member_type === "Junior B") &&
                    currentChosenCourt?.name !== "B11" &&
                    currentChosenCourt
                  ) {
                    return (
                      <span className="text-m text-danger">
                        Du kan bara boka tränare på bana 11
                      </span>
                    );
                  } else if (
                    permission &&
                    (permission.member_type === "Junior" ||
                      permission.member_type === "Junior B") &&
                    currentChosenCourt?.name === "B11" &&
                    currentChosenCourt &&
                    isOutsideJuniorTime
                  ) {
                    return (
                      <span className="text-m text-danger">
                        Du kan inte boka tider mellan 15 - 19 på veckodagar
                      </span>
                    );
                  } else if (
                    permission &&
                    (permission.member_type === "Junior" ||
                      permission.member_type === "Junior B") &&
                    currentChosenCourt?.name === "B11" &&
                    currentChosenCourt &&
                    currentChosenSummaryItem.coach_available.length === 0
                  ) {
                    return (
                      <span className="text-m text-danger">
                        Du kan bara boka tider där tränare är tillgängliga
                      </span>
                    );
                  }
                  if (
                    currentChosenSummaryItem.booking &&
                    currentChosenSummaryItem.booking.length > 0
                  ) {
                    return (
                      <span className="text-m text-danger">
                        Kontakta admin för att boka denna tid.
                      </span>
                    );
                  }
                  if (currentChosenSummaryItem && !userData.frozen) {
                    return checkCanHaveAnotherBooking(
                      currentChosenSummaryItem.start_time
                    ) ? (
                      typeOfSelectedItem === "booking" ? (
                        <div className="user-booking-action">
                          <button
                            className="full-width"
                            onClick={() => handleInitiateBooking()}
                          >
                            {currentChosenSummaryItem.coach_available.length > 0
                              ? "Välj medspelare (tränare tillgänglig)"
                              : "Välj medspelare"}
                          </button>
                        </div>
                      ) : (currentChosenSummaryItem.activity &&
                          currentChosenSummaryItem.activity.members_with_access
                            .length === 0) ||
                        currentChosenSummaryItem.activity.participants_limit ===
                          0 ? (
                        <div className="user-booking-action">
                          <span className="text-danger">
                            Denna aktivitet kan inte bokas
                          </span>
                        </div>
                      ) : (
                        <div className="user-booking-action">
                          <button
                            className="full-width"
                            onClick={() => handleBookActivity()}
                            disabled={isLoading}
                          >
                            Boka denna aktivitet
                          </button>
                        </div>
                      )
                    ) : (
                      <span className="text-m text-danger">
                        Ditt medlemskap tillåter inte fler bokningar
                      </span>
                    );
                  }

                  return null;
                })()}
              </div>
            </div>
          </div>
        </div>
        {activeBookingsForUser.length > 0 && (
          <div className="user-booking-bottom-default">
            <span className="text-m">Aktiva bokningar</span>

            {activeBookingsForUser.map((booking, index) => {
              const startDate = new Date(booking.datetime_start);
              const { weekday, dateAndMonth, hour } = formatDateToSwedish(
                new Date(startDate)
              );
              const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
              const formattedEndHour = endDate.toTimeString().substring(0, 5);
              const courtName = courts.find(
                (court) => court.court_id === booking.court_id
              )?.name;
              const hasStarted = isBefore(startDate, new Date());

              const memberName =
                capitalizeWords(userData?.first_name || "") +
                " " +
                capitalizeWords(userData?.last_name || "") +
                " (" +
                booking.member_id +
                ")";
              const finalPrice = booking.final_price
                .map((booking) => booking.price)
                .reduce((a, b) => a + b, 0);

              return (
                <div key={index} className="user-booking-specifics">
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Datum</span>
                    <span>
                      {weekday}, {dateAndMonth}
                    </span>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Tid & Bana</span>
                    <div className="info-row-inner-gap">
                      <span>
                        {hour} - {formattedEndHour}
                      </span>
                      <span> {courtName}</span>
                    </div>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Huvudbokare</span>
                    <span>{memberName}</span>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Pris: </span>
                    <span>{finalPrice} kr</span>
                  </div>

                  {booking.coach_id.map((coach, index) => {
                    const coachData = coaches.find((c) => c.coach_id === coach);

                    if (!coachData) return;

                    const coach_price = booking.final_price.find((event) => {
                      return event.event.includes("coach_price");
                    });
                    const coachString =
                      capitalizeWords(coachData.first_name) +
                      " " +
                      capitalizeWords(coachData.last_name) +
                      " (" +
                      coach +
                      ")" +
                      " - " +
                      coach_price?.price +
                      "kr";

                    return (
                      <div
                        key={index}
                        className="user-booking-info-row-wrapper text-m"
                      >
                        {index === 0 && <span>Tränare</span>}
                        <span>{coachString}</span>
                      </div>
                    );
                  })}

                  {booking.co_players.map((co_player, index) => {
                    const hasCoString = booking.guests.length === 0;
                    const coPlayerData = coPlayersData.find(
                      (coPlayer) => coPlayer.member_id === co_player.member_id
                    );
                    const priceForPlayer = booking.final_price.find((event) => {
                      return event.event.includes(co_player.member_id);
                    });
                    const priceString = priceForPlayer?.price || "0";
                    return (
                      <div
                        key={index}
                        className="user-booking-info-row-wrapper text-m"
                      >
                        {!hasCoString && index === 0 ? (
                          <span>Medspelare</span>
                        ) : (
                          <span></span>
                        )}
                        <span>
                          {coPlayerData
                            ? capitalizeWords(coPlayerData.first_name) +
                              " " +
                              capitalizeWords(coPlayerData.last_name) +
                              " (" +
                              co_player.member_id +
                              ")"
                            : co_player.member_id}
                          {" - "}
                          {priceString} kr
                        </span>
                      </div>
                    );
                  })}
                  {booking.guests.map((guest, index) => {
                    const hasCoString = booking.co_players.length === 0;
                    const priceForPlayer = booking.final_price.find((event) => {
                      return event.event.includes("guest");
                    });
                    const priceString = priceForPlayer?.price || "0";
                    return (
                      <div
                        key={index}
                        className="user-booking-info-row-wrapper text-m"
                      >
                        {hasCoString && index === 0 ? (
                          <span>Medspelare</span>
                        ) : (
                          <span></span>
                        )}
                        <span>Gäst {priceString} kr</span>
                      </div>
                    );
                  })}
                  {!hasStarted && (
                    <>
                      <div className="user-booking-info-row-wrapper text-m">
                        <span></span>
                        <a onClick={() => openEditCoPlayersPopup(booking)}>
                          Redigera medspelare
                        </a>
                      </div>
                      <div className="user-booking-info-row-wrapper text-m">
                        <span></span>
                        <a
                          className="danger"
                          onClick={() => openConfirmationPopup(booking)}
                        >
                          Avboka
                        </a>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {activeBookingsAsCoPlayer.length > 0 && (
          <div className="user-booking-bottom-default">
            <span className="text-m">Aktiva bokningar som medspelare</span>

            {activeBookingsAsCoPlayer.map((booking, index) => {
              const startDate = new Date(booking.date);
              const { weekday, dateAndMonth, hour } = formatDateToSwedish(
                new Date(startDate)
              );
              const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
              const formattedEndHour = endDate.toTimeString().substring(0, 5);
              const courtName = courts.find(
                (court) => court.court_id === booking.court_id
              )?.name;

              const memberName =
                capitalizeWords(booking.booker_name || "") +
                " (" +
                booking.booker +
                ")";

              return (
                <div key={index} className="user-booking-specifics">
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Datum</span>
                    <span>
                      {weekday}, {dateAndMonth}
                    </span>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Tid & Bana</span>
                    <div className="info-row-inner-gap">
                      <span>
                        {hour} - {formattedEndHour}
                      </span>
                      <span> {courtName}</span>
                    </div>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Huvudbokare</span>
                    <span>{memberName}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {userQueueSpots.length > 0 && (
          <div className="user-booking-bottom-default">
            <span className="text-m">Väntelista</span>
            <div className="user-queuetime-wrapper">
              {userQueueSpots.map((queueTime, index) => {
                const courtTypeText =
                  queueTime.court_type === "INDOOR" ? "Inomhus" : "Utomhus";
                const { weekday, dateAndMonth, hour } = formatDateToSwedish(
                  new Date(queueTime.datetime_start)
                );
                return (
                  <div key={index} className="user-queuetime-item text-m">
                    <div className="user-queuetime-info-row-wrapper">
                      <span>Datum</span>
                      <span>
                        {weekday}, {dateAndMonth}
                      </span>
                    </div>
                    <div className="user-queuetime-info-row-wrapper">
                      <span>Tid</span>
                      <span>{hour}</span>
                    </div>
                    <div className="user-queuetime-info-row-wrapper">
                      <span>Typ av bana</span>
                      <span>{courtTypeText}</span>
                    </div>
                    <div className="user-queuetime-info-row-wrapper">
                      <span></span>
                      <a
                        className="danger"
                        href="#"
                        onClick={() =>
                          handleRemoveFromQueue(
                            queueTime.datetime_start,
                            queueTime.PK
                          )
                        }
                      >
                        Ta bort köplats
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {activeActivitiesForUser.length > 0 && (
          <div className="user-booking-bottom-default">
            <span className="text-m">Dina aktiviteter</span>
            {activeActivitiesForUser.map((activity, index) => {
              const startDate = new Date(activity.datetime_start);
              const { weekday, dateAndMonth, hour } = formatDateToSwedish(
                new Date(startDate)
              );
              const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
              const formattedEndHour = endDate.toTimeString().substring(0, 5);
              const courtName = courts.find(
                (court) => court.court_id === activity.court_id[0]
              )?.name;

              const courtType = courts.find(
                (court) => court.court_id === activity.court_id[0]
              )?.court_type;

              const activityPrice = activity.price;

              const activitySlot = activitySlots.find(
                (slot) =>
                  slot.activity.name === activity.name &&
                  slot.start_time.slice(0, 5) ===
                    activity.datetime_start.slice(11, 16)
              );

              const participantsLength =
                activitySlot?.activity.participants.length ||
                activity.participants.length;
              const participantsLimit = activity.participants_limit;
              const activityColorClass = activity.color;

              return (
                <div
                  key={index}
                  className={`user-booking-specifics ${activityColorClass}`}
                >
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Datum</span>
                    <span>
                      {weekday}, {dateAndMonth}
                    </span>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Tid & Bana</span>
                    <div className="info-row-inner-gap">
                      <span>
                        {hour} - {formattedEndHour}
                      </span>
                      <span> {courtName}</span>
                    </div>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Aktivitet</span>
                    <span>{activity.name.split("%-%")[0]}</span>
                  </div>

                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Pris:</span>
                    <span>{activityPrice} kr</span>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Deltagare</span>
                    <span>
                      {participantsLength} / {participantsLimit}
                    </span>
                  </div>

                  {activity.comment && (
                    <div className="user-booking-info-row-wrapper flex-down ">
                      <span className="text-m">Kommentar:</span>
                      <span className="text-m">{activity.comment}</span>
                    </div>
                  )}
                  <div className="user-booking-info-row-wrapper text-m">
                    <span></span>
                    <a
                      className="danger"
                      onClick={() => openActivityConfirmationPopup(activity)}
                    >
                      Avboka
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeRehabBookingsForUser.length > 0 && (
          <div className="user-booking-bottom-default">
            <span className="text-m">Rehab bokningar</span>
            {activeRehabBookingsForUser.map((booking, index) => {
              const startDate = new Date(booking.datetime_start);
              const { weekday, dateAndMonth, hour } = formatDateToSwedish(
                new Date(startDate)
              );
              const endDate = new Date(startDate.getTime() + 15 * 60 * 1000);
              const formattedEndHour = endDate.toTimeString().substring(0, 5);

              return (
                <div key={index} className={`user-booking-specifics `}>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Datum</span>
                    <span>
                      {weekday}, {dateAndMonth}
                    </span>
                  </div>
                  <div className="user-booking-info-row-wrapper text-m">
                    <span>Tid</span>
                    <div className="info-row-inner-gap">
                      <span>
                        {hour} - {formattedEndHour}
                      </span>
                    </div>
                  </div>

                  <div className="user-booking-info-row-wrapper text-m">
                    <span></span>
                    <a
                      className="danger"
                      onClick={() => openRehabConfirmationPopup(booking)}
                    >
                      Avboka
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <CreateBookingPopup
        showPopup={showCreateBookingPopup}
        filterDate={filterDate}
        courtType={currentCourtType}
        court={currentChosenCourt}
        user={user}
        timeAndCourtString={timeAndCourtString}
        onConfirm={() => handleConfirmFromBooking()}
        onClose={() => {
          setShowCreateBookingPopup(false);
          setIsBookingInitiated(false);
        }}
        bookingToConfirm={bookingResponse}
        coachesList={currentChosenSummaryItem?.coach_available || []}
        chosenCoach={currentSelectedCoach}
        latestCoPlayers={latestCoPlayers}
        coaches={coaches as ICoach[]}
        price={currentChosenSummaryItem?.price}
        permissions={permissions}
        prices={prices}
      />
      <EditCoPlayersPopup
        cognitoUser={user}
        user={null}
        showPopup={showEditCoPlayersPopup}
        onClose={() => setShowEditCoPlayersPopup(false)}
        booking={bookingToEditCoPlayers}
        coachesList={availableCoachesForEditCoPlayerSlot}
        latestCoPlayers={latestCoPlayers}
        courts={courts}
        coaches={coaches as ICoach[]}
        onConfirm={() => fetchChosenItems({ fetchActiveBookings: true })}
        userData={userData}
        prices={prices}
      />
      <BookRehabSlotPopup
        user={user}
        showPopup={showBookRehabPopup}
        onClose={() => setShowBookRehabPopup(false)}
        onConfirm={() =>
          fetchChosenItems({
            fetchRehabSlots: true,
            fetchActiveRehabBookingsByMember: true,
          })
        }
        rehabSummary={rehabSummary}
        date={filterDate}
        activeBookings={activeRehabBookingsForUser}
      />
      <HandleConfirmationPopup
        showPopup={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={() => handleUnbookBooking(bookingToCancel as IBooking)}
        questionText={cancelBookingWarningText}
        confirmText={"Avboka"}
        denyText={"Avbryt"}
        loading={isCancelingBooking}
      />
      <HandleConfirmationPopup
        showPopup={showActivityConfirmationPopup}
        onClose={() => setShowActivityConfirmationPopup(false)}
        onConfirm={() => handleUnbookActivity(activityToCancel as IActivity)}
        questionText={"Är du säker på att du vill avboka bokningen?"}
        confirmText={"Avboka"}
        denyText={"Avbryt"}
      />
      <HandleConfirmationPopup
        showPopup={showRehabConfirmationPopup}
        onClose={() => setShowRehabConfirmationPopup(false)}
        onConfirm={() =>
          handleCancelRehabBooking(rehabBookingToCancel as IRehabBooking)
        }
        questionText={"Är du säker på att du vill avboka bokningen?"}
        confirmText={"Avboka"}
        denyText={"Avbryt"}
        loading={isCancelingRehabBooking}
      />
    </div>
  );
}

export default UserBookings;
