import "./CreateUserPopup.scss";
import { Fragment, useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IPermission } from "../../../types/api";
import { toast } from "react-toastify";
import { createUser } from "../../../api/users/users";
import {
  capFirstAndRemoveDash,
  capitalizeWords,
  checkDateDash,
  formatPersonalNumber,
  formatPhoneNumber,
} from "../../../shared/utility";
import { TitleEnum } from "../../../shared/enums";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import {
  emailRegex,
  phoneRegex,
  socialSecurityRegex,
} from "../../../shared/regex";
import remove from "../../../assets/images/remove.svg";
import { formatDate } from "../../../shared/dateUtils";
import { createQueueUser } from "../../../api/queueUser/queueUsers";

interface ICreateUserPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  permissions: IPermission[];
}
interface UserData {
  member_id?: string;
  account_type: string;
  massemail: boolean;
  first_name: string;
  last_name: string;
  member_type: string;
  email: string;
  email_cc: string;
  phone_number: string;
  home_phone: string;
  work_phone: string;
  birth_data: string;
  adress: string;
  city: string;
  rehab_access: boolean;
  gym_access: boolean;
  gender: string;
  activated_at: string;
  zip_code: string;
  country: string;
  company: string;
  title: string;
  ranking: number;
  hidden_info: boolean;
  [key: string]: string | boolean | number | undefined;
}

const initialUserData: UserData = {
  member_id: "",
  account_type: "USER",
  first_name: "",
  last_name: "",
  member_type: "Aktiv spelrätt",
  email: "",
  email_cc: "",
  phone_number: "+46",
  home_phone: "",
  work_phone: "",
  birth_data: "",
  massemail: false,
  adress: "",
  city: "",
  rehab_access: false,
  gym_access: false,
  gender: "",
  activated_at: formatDate(new Date(), "yy-MM-dd"),
  zip_code: "",
  country: "",
  company: "",
  title: "",
  ranking: -1,
  hidden_info: false,
};

function CreateUserPopup(props: ICreateUserPopupProps) {
  const [showHandleCreateMemeberPopup, setShowHandleCreateMemeberPopup] =
    useState(false);
  const titleValues = Object.values(TitleEnum);
  const rankingValues = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const [userData, setUserData] = useState(initialUserData);
  const currentPermission = props.permissions.find(
    (permission) => permission.member_type === userData.member_type
  );
  const [home_phoneToggle, setHomePhoneToggle] = useState(false);
  const [work_phoneToggle, setWorkPhoneToggle] = useState(false);
  const [hasUserDataChanged, setHasUserDataChanged] = useState(false);
  const [ccEmails, setCcEmails] = useState<string[]>([""]);
  const [member_id, setMemberId] = useState("");
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const isInput = (element: EventTarget): element is HTMLInputElement =>
      element instanceof HTMLInputElement;

    const { name, value } = e.target;
    let inputValue: string | boolean | number = value;
    if (isInput(e.target) && e.target.type === "checkbox") {
      inputValue = e.target.checked;
    }
    switch (name) {
      case "first_name":
        break;
      case "member_id":
        if (isNaN(Number(value))) return;
        setMemberId(value);
        break;
      case "last_name":
        break;
      case "city":
        break;
      case "country":
        break;
      case "activated_at":
        inputValue = checkDateDash(value);
        break;
      case "email":
        inputValue = value.toLowerCase();
        break;
      case "company":
        inputValue = capitalizeWords(value);
        break;
      case "birth_data":
        inputValue = formatPersonalNumber(value);
        break;
      case "phone_number":
        inputValue = formatPhoneNumber(value);
        break;
      case "home_phone":
        inputValue = formatPhoneNumber(value);
        break;
      case "work_phone":
        inputValue = formatPhoneNumber(value);
        break;
      case "member_type":
        if (
          value !== "Aktiv spelrätt" &&
          value !== "Temporär spelrätt" &&
          value !== "Företag" &&
          value !== "Företag B" &&
          value !== "Hedersledarmot" &&
          value !== "Elitnål"
        ) {
          setUserData((prevData) => ({
            ...prevData,
            title: "",
            ranking: -1,
          }));
        }
        if (
          props.permissions.find(
            (permission) => permission.member_type === value
          )?.gym_access === false
        ) {
          setUserData((prevData) => ({
            ...prevData,
            gym_access: false,
          }));
        }

        setUserData((prevData) => ({
          ...prevData,
          hidden_info: currentPermission?.hidden || false,
        }));

        break;
      case "title":
        break;

      default:
        break;
    }
    setUserData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  useEffect(() => {
    !home_phoneToggle &&
      setUserData((prevData) => ({
        ...prevData,
        home_phone: "",
      }));
  }, [home_phoneToggle]);

  useEffect(() => {
    !work_phoneToggle &&
      setUserData((prevData) => ({
        ...prevData,
        work_phone: "",
      }));
  }, [work_phoneToggle]);

  useEffect(() => {
    const isDataChanged = () => {
      return Object.keys(initialUserData).some(
        (key) => userData[key] !== initialUserData[key]
      );
    };
    setHasUserDataChanged(isDataChanged());
  }, [userData]);

  const handleClose = () => {
    setShowHandleCreateMemeberPopup(false);
    setMemberId("");
    setCcEmails([""]);
    setUserData(initialUserData);
    props.onClose();
  };

  const handleCcEmailChange = (index: number, value: string) => {
    setCcEmails((prevEmails) => {
      const updatedEmails = prevEmails.map((email, i) =>
        i === index ? value : email
      );
      return updatedEmails;
    });
  };

  const addCcEmail = () => {
    setCcEmails((prevEmails) => [...prevEmails, ""]);
  };

  const removeCcEmail = (index: number) => {
    setCcEmails((prevEmails) => prevEmails.filter((_, i) => i !== index));
  };

  const handleCreateUser = async () => {
    try {
      const concatenatedCcEmails = ccEmails.filter(Boolean).join("; ");

      const userToCreate = {
        ...userData,
        email_cc: concatenatedCcEmails,
      };
      let set_id = false;
      if (member_id !== "") {
        set_id = true;
      }
      if (userToCreate.member_type === "Kö") {
        const createQueueUserResponse = await createQueueUser(
          userToCreate,
          set_id
        );
        if (!createQueueUserResponse) return;
        toast.success(
          `Användare: ${userToCreate.first_name} tillagd i som kö-medlem`
        );
      } else {
        const createUserResponse = await createUser(userToCreate, set_id);
        if (!createUserResponse) return;
        toast.success(`Användare: ${userToCreate.first_name} skapad`);
      }

      handleClose();
      props.onConfirm();
    } catch (error: any) {
      const errorMessage =
        error.message || "An unexpected error occurred. Please try again.";
      toast.error(errorMessage);
    }
  };

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={() => setShowHandleCreateMemeberPopup(true)}
      leftTopElement={<span className="text-m">Lägg till ny medlem</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateUser();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="member_id" className="text-m">
                    Id
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="memberId"
                    name="member_id"
                    maxLength={4}
                    minLength={4}
                    placeholder="Om denna är blank genereras ett random id"
                    value={member_id}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="firstName" className="text-m">
                    Förnamn*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="firstName"
                    name="first_name"
                    placeholder="Förnamn"
                    value={capitalizeWords(userData?.first_name || "")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="lastName" className="text-m">
                    Efternamn*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="lastName"
                    name="last_name"
                    placeholder="Efternamn"
                    value={capitalizeWords(userData?.last_name || "")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="birthData" className="text-m">
                    Personnummer*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="birthData"
                    name="birth_data"
                    placeholder="yymmdd-xxxx"
                    pattern={socialSecurityRegex}
                    required
                    maxLength={11}
                    value={userData.birth_data}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="phoneNr" className="text-m">
                    Telefon
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="phoneNr"
                    required
                    name="phone_number"
                    placeholder="+46 70-123 4567"
                    pattern={phoneRegex}
                    value={userData.phone_number}
                    maxLength={18}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Hem telefon?</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="home_phone_toggle"
                      checked={home_phoneToggle}
                      onChange={(e) => {
                        setHomePhoneToggle((prevToggle) => !prevToggle);
                        if (e.target.value === "true") {
                          setUserData((prevData) => ({
                            ...prevData,
                            home_phone: "",
                          }));
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {home_phoneToggle && (
                  <div className="general-popup-form-field-divided-section">
                    <input
                      className="on-transparent text-m"
                      type="text"
                      id="homePhoneNr"
                      name="home_phone"
                      placeholder="+46 70-123 4567"
                      pattern={phoneRegex}
                      value={userData.home_phone}
                      maxLength={18}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Jobb telefon?</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="work_phone_toggle"
                      checked={work_phoneToggle}
                      onChange={(e) => {
                        setWorkPhoneToggle((prevToggle) => !prevToggle);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {work_phoneToggle && (
                  <div className="general-popup-form-field-divided-section">
                    <input
                      className="on-transparent text-m"
                      type="text"
                      id="workPhoneNr"
                      name="work_phone"
                      placeholder="+46 70-123 4567"
                      pattern={phoneRegex}
                      value={userData.work_phone}
                      maxLength={18}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="gender" className="text-m">
                    Kön
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    required
                    className="text-m"
                    value={userData.gender}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled>
                      Välj kön
                    </option>
                    <option value="man">Man</option>
                    <option value="kvinna">Kvinna</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-m">
                Medlemskap*
              </label>
              <select
                id="memberType"
                name="member_type"
                value={userData.member_type}
                onChange={(e) => handleInputChange(e)}
                required
                className="text-m"
              >
                <option value="" disabled>
                  Välj medlemskap
                </option>
                {props.permissions.map((permission) => {
                  const memberType = capFirstAndRemoveDash(
                    permission.member_type
                  );
                  return (
                    <option
                      value={permission.member_type}
                      key={permission.permission_id}
                    >
                      {memberType}
                    </option>
                  );
                })}
              </select>
              {props.permissions.map((permission, index) => {
                if (permission.member_type !== userData.member_type)
                  return null;
                const ranking = permission.ranking;
                const payment_card = permission.payment_card;
                const title = permission.title;
                const gym_access = permission.gym_access;
                return (
                  <Fragment key={index}>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Gym access</span>
                      <label className="toggle text-m">
                        <input type="checkbox" checked={gym_access} readOnly />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Betalkort</span>
                      <label className="toggle text-m">
                        <input
                          type="checkbox"
                          checked={payment_card}
                          readOnly
                        />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Ranking</span>
                      <label className="toggle text-m">
                        <input type="checkbox" checked={ranking} readOnly />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Titel</span>
                      <label className="toggle text-m">
                        <input type="checkbox" checked={title} readOnly />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {userData.member_type === "Aktiv spelrätt" ||
            userData.member_type === "Företag" ||
            userData.member_type === "Företag B" ||
            userData.member_type === "Hedersledarmot" ||
            userData.member_type === "Elitnål" ? (
              <div className="general-popup-form-field">
                <label htmlFor="title" className="text-m">
                  Titel
                </label>
                <select
                  id="title"
                  name="title"
                  value={userData.title}
                  onChange={(e) => handleInputChange(e)}
                  className="text-m"
                >
                  <option value="">Ingen titel</option>
                  {titleValues.map((title, index) => {
                    return (
                      <option key={index} value={title}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            {userData.member_type === "Aktiv spelrätt" ||
            userData.member_type === "Temporär spelrätt" ||
            userData.member_type === "Elitnål" ||
            userData.member_type === "Företag" ||
            userData.member_type === "Företag B" ||
            userData.member_type === "Hedersledarmot" ? (
              <div className="general-popup-form-field">
                <label htmlFor="ranking" className="text-m">
                  Ranking
                </label>
                <select
                  id="ranking"
                  name="ranking"
                  value={userData.ranking}
                  onChange={(e) => handleInputChange(e)}
                  className="text-m"
                >
                  <option value={-1}>Ingen ranking</option>
                  {rankingValues.map((ranking, index) => {
                    return (
                      <option key={index} value={ranking}>
                        {ranking / 2}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            <div className="general-popup-form-field">
              <div className="text-m">Övrigt - kan redigeras</div>
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Sökbar för medlemmar</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="hidden_info"
                      checked={!userData.hidden_info}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          hidden_info: !e.target.checked,
                        }))
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Massmail</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="massmail"
                      checked={userData.massemail}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          massemail: e.target.checked,
                        }))
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {currentPermission?.gym_access && (
                  <div className="general-popup-form-field-divided-section flex-between">
                    <span className="text-m">Gym</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        name="hidden_info"
                        checked={userData.gym_access}
                        onChange={(e) =>
                          setUserData((prevData) => ({
                            ...prevData,
                            gym_access: e.target.checked,
                          }))
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                )}

                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Rehab access</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="hidden_info"
                      checked={userData.rehab_access}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          rehab_access: e.target.checked,
                        }))
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="activated_at" className="text-m">
                    Aktivera
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="activated_at"
                    name="activated_at"
                    required
                    placeholder="ÅÅ-MM-DD"
                    maxLength={8}
                    pattern="^\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$"
                    value={userData.activated_at}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="email" className="text-m">
                    E-postadress*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="email"
                    id="email"
                    name="email"
                    required
                    pattern={emailRegex}
                    placeholder="Ange E-postadress"
                    value={userData.email}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                {ccEmails.map((email, index) => (
                  <div
                    className="general-popup-form-field-divided-section cc-wrapper"
                    key={index}
                  >
                    <label htmlFor={`emailCC${index}`} className="text-m">
                      E-postadress cc {index !== 0 && index + 1}
                    </label>
                    <input
                      className="on-transparent text-m"
                      type="email"
                      id={`emailCC${index}`}
                      name={`email_cc_${index}`}
                      placeholder="Ange E-postadress"
                      pattern={emailRegex}
                      value={email}
                      onChange={(e) =>
                        handleCcEmailChange(index, e.target.value)
                      }
                    />
                    {index !== 0 && (
                      <img
                        src={remove}
                        className="pointer remove-cc-email"
                        onClick={() => removeCcEmail(index)}
                        alt="Remove cc address"
                      />
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addCcEmail}
                  className="text-m add-cc-button"
                >
                  Lägg till cc e-postadress
                </button>
              </div>
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="company" className="text-m">
                Företag
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="company"
                name="company"
                placeholder="Ange företag"
                value={userData.company}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="adress" className="text-m">
                    Adress
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="adress"
                    name="adress"
                    placeholder="Ange adress"
                    value={userData.adress}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="city" className="text-m">
                    Stad
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Ange stad"
                    value={userData.city}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="zipcode" className="text-m">
                    Postnummer
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="zipcode"
                    name="zip_code"
                    placeholder="Ange postnummer"
                    maxLength={6}
                    value={userData.zip_code}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="country" className="text-m">
                    Land
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Ange land"
                    value={userData.country}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="general-popup-flex-end">
            <button
              type="submit"
              className="text-m"
              disabled={!hasUserDataChanged}
            >
              Skapa ny medlem
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleCreateMemeberPopup}
        onClose={() => setShowHandleCreateMemeberPopup(false)}
        onConfirm={handleClose}
        questionText={`Är du säker på att avbryta?`}
        confirmText="Avbryt"
      />
    </Popup>
  );
}

export default CreateUserPopup;
